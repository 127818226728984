import React, { useState, useEffect } from "react";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsArrowLeft, BsArrowRight, BsYoutube } from "react-icons/bs";
import VideoDialog from '../DialogBox/VideoDailouge'


const FeaturePreviewCard = ({ featureData }) => {
    const [selectedCard, setSelectedCard] = useState(0);

    const [isVideoDialogOpen, setVideoDialogOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');

    var settings = {
        arrows: true,
        slidesToShow: 1,
        speed: 2000,
        dots: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        useCss: true,
        dotsClass: 'slick-dots ${style.dots}',
        navSpeed: 2000,
        infinite: true,
        autoplay: false,
        centerMode: true, // Center the images
        centerPadding: '0', // No padding around centered images
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {

    }, []);

    const openVideoDialog = (videoUrl) => {
        setCurrentVideoUrl(videoUrl);
        setVideoDialogOpen(true);
    };

    const closeVideoDialog = () => {
        setCurrentVideoUrl('');
        setVideoDialogOpen(false);
    };

    const handleCardClick = (index) => {
        setSelectedCard(index);
    };


    return (
        <div>
            <div className="justify-center items-center py-5 xl:px-52 bg-sky-50 ">
                {/* Left cards */}

                <div className="text-center xl:text-[34px] text-[20px] 
                text-[#0e7cf2] font-bold">
                    <h1>{featureData.title}</h1>
                </div>


                <div className="hidden md::hidden sm:hidden lg::block xl:block">
                    {/* Mobile App Preview */}
                    <div className="flex justify-center items-center py-5 bg-sky-50 ">
                        <div className="">
                            {/* You can place your mobile app preview here */}
                            <img src={featureData.data[selectedCard].image} alt=""
                                className='w-[700px]'
                            />
                        </div>

                        {/* Right cards */}
                        <div className="flex flex-col items-center xl:ml-0 md:ml-18" id="feature-text-card">
                            {featureData.data.map((data, index) => (
                                <div className="flex justify-center items-center ">
                                    <hr class={`${selectedCard === index ? "visible" :
                                        "invisible"} w-32 h-1.5 bg-[#0e7cf2] top-10`}></hr>
                                    <div
                                        key={data.id}
                                        className={`${selectedCard === index
                                            ? "bg-white border-4 border-[#0e7cf2]"
                                            : "bg-sky-50"
                                            } p-4 rounded-xl shadow-lg mb-10 flex items-center justify-center`}
                                        onClick={() => handleCardClick(index)}
                                    > {/* Icon */}
                                        {
                                            data.icon !== null ?
                                                <img src={data.icon} alt=""
                                                    className='w-20 h-20 mr-5' />
                                                : null
                                        }

                                        <div>
                                            {/* Title */}
                                            <h2 className="text-lg font-semibold text-[22px]">{data.title}</h2>
                                            {/* Description */}
                                            <p className="text-sm text-gray-500">{data.description}</p>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='lg:flex md:flex justify-center items-center lg:space-x-3 md:space-x-3 mb-10'>
                        <button onClick={() => openVideoDialog('video-url-2.mp4')}
                            className='rounded-lg  text-lg py-3 mt-5 bg-black
                     text-white hover:scale-105 px-5 text-center text-[14px]
                    '>
                            <div className="flex justify-center items-center">
                                <BsYoutube className=" text-[#CD201F] text-[28px]">
                                </BsYoutube>
                                <div className="px-2 text-[14px] font-bold">
                                    Watch Now
                                </div>
                            </div>

                        </button>
                        <button onClick={() => window.location.href = featureData.link}
                            className='rounded-lg  text-lg py-3 mt-5 bg-[#0e7cf2]
                     text-white hover:scale-105 px-5 text-center text-[14px]
                    '>
                            <div className="flex justify-center items-center">
                                <div className="px-2 text-[14px] font-bold">
                                   View More
                                </div>
                            </div>

                        </button>
                    </div>
                </div>

                <div className="container mx-auto lg:mt-0 my-0 
              md::block sm:block xl:hidden">
                    <Slider
                        className={`transition-transform duration-200
                ease-in-out transform-gpu ${scrollY > 0 ? '-translate-y-0' : ''
                            }`}
                        {...settings}
                    >

                        {featureData.data.map((feature, index) => (
                            <div key={index} className="py-8 px-6 mt-2
                    transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 items-center">
                                <div className="text-center items-center">
                                    <span class="absolute left-0 top-0">
                                    </span>
                                    <img src={feature.image} alt=""
                                        className='mx-auto'
                                        width="150px"

                                    />
                                </div>

                                <h1 className='mt-5 text-black lg:text-3xl text-[16px] mb-1 font-[600] text-center'>{feature.title}</h1>
                                <p className='mt-0  lg:text-[16px] text-[#575757] text-[12px] px-5 text-center'>{feature.description}</p>

                            </div>
                        ))}
                    </Slider>
                    <div className='flex justify-center mb-10'>
                        <button onClick={() => openVideoDialog('video-url-2.mp4')}
                            className='rounded-lg  text-lg py-3 mt-5 bg-black
                     text-white hover:scale-105 px-5 text-center text-[14px]
                    '>
                            <div className="flex justify-center items-center">
                                <BsYoutube className=" text-[#CD201F] text-[28px]">
                                </BsYoutube>
                                <div className="px-2 text-[14px] font-bold">
                                    Watch Now
                                </div>
                            </div>

                        </button>
                    </div>
                </div>


            </div>
            <VideoDialog isOpen={isVideoDialogOpen} onClose={closeVideoDialog} videoUrl={featureData.videoLink} />
        </div>
    );
};

const CustomPrevArrow = (props) => {
    return (
        <button {...props} className="absolute left-5 top-48 transform -translate-y-1/2 
      text-white p-2 rounded-2xl bg-[#0e7cF2]">
            <BsArrowLeft />
        </button>
    );
};

const CustomNextArrow = (props) => {
    return (
        <button {...props} className="absolute right-5 top-48 transform -translate-y-1/2 
       text-white p-2 rounded-2xl bg-[#0e7cF2]">
            <BsArrowRight />
        </button>
    );
};
export default FeaturePreviewCard;
