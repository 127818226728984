import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Nav from './Component/Header/NavBar';
import Homepage from './Homepage';
import Footer from './Component/Footer/Footer';
import ScrollToTop from './Component/scrollTop/ScrollToTop';
import ContactusForm from './Component/Section/ContactusForm';
import Privacy from './Component/Section/Privacy';
import Termsandcondtion from './Component/Section/Termsandcondtion';
import BlogPost from './Component/Blog/Blog';
import PageTop from './Component/scrollTop/PageTop';
import BlogContent from './Component/Blog/Blogcontent';
import { BlogProvider } from './Component/Blog/BlogContext';
import CreateBlog from './Component/Blog/CreateBlog';
import FaqContent from './Component/Faq/FaqContent';
import Features from './Component/Features/Features';
import RefundPolicy from './Component/Section/RefundPolicy';
import Pricing from './Component/Pricing/Pricing';
import SideButton from './Component/SideButton/SideButton';
import InventoryManagment from './Component/InventoryManagement/InventoryManagment';
import OnlineStore from './Component/OnlineStore/OnlineStore';
import QuickBilling from './Component/QuickBilling/QuickBilling';
import ReportsAnalytics from './Component/ReportsAnalytics/ReportsAnalytics';
import CustomerUdhaar from './Component/CustomerUdhaar/CustomerUdhaar';
import ExpenseManagment from './Component/ExpenseManagement/ExpenseManagment';
import ContentDialog from './Component/DialogBox/ContentDialog';

// Define a custom 404 component
const NotFound = () => {
  return (
    <div className="text-center my-20">
      <h1 className="mb-4 text-6xl font-semibold text-red-500">404</h1>
      <p className="mb-4 text-lg text-gray-600">Oops! Looks like you're lost.</p>
      <div className="animate-bounce">
        <svg className="mx-auto h-16 w-16 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
        </svg>
      </div>
      <p className="mt-4 text-gray-600">Let's get you back <a href="/" className="text-blue-500">home</a>.</p>
    </div>
  );
};

const App = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    console.log("Setting up the timer for the popup");

    if (window.location.pathname === '/') {
      console.log("Setting up the timer for the popup");

      const timer = setTimeout(() => {
        console.log("10 seconds passed, showing popup");
        handlePopupShow(); // Function to show the popup
      }, 10000); // 10 seconds

      // Clean up the timer on unmount
      return () => clearTimeout(timer);
    }
  }, []);

  const handlePopupShow = () =>{
    setShowPopup(true);
  }

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <BlogProvider>
        <Nav />
        <PageTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/contact-us" element={<ContactusForm />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-conditions" element={<Termsandcondtion />} />
          <Route path="/inventory-managment" element={<InventoryManagment />} />
          <Route path="/online-store" element={<OnlineStore />} />
          <Route path="/quick-billing" element={<QuickBilling />} />
          <Route path="/reports-analytics" element={<ReportsAnalytics />} />
          <Route path="/customer-udhaar" element={<CustomerUdhaar />} />
          <Route path="/expense-managment" element={<ExpenseManagment />} />
          <Route path="/blog" element={<BlogPost />} />
          <Route path="/blog-post/:title" element={<BlogContent />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/create-blog" element={<CreateBlog />} />
          <Route path="/faq" element={<FaqContent title="FAQ's" />} />
          <Route path="/feature" element={<Features />} />
          <Route path="/feature/:title" element={<Features />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <SideButton/>
        <ScrollToTop />
        <Footer />
      </BlogProvider>

      {/* Popup after 5 seconds */}
      {showPopup && (
        <ContentDialog isOpen={handlePopupShow} onClose={closePopup} />
      )}
    </>
  );
};

export default App;