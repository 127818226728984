import React, { useEffect, useState } from 'react'
import Googleplay from '../Buttom/Googleplay'
import GetlinkHome from '../AppgetLinkdownload/Getlink_Home';
import AppleStoreButton from '../Buttom/Applestore';
import Window from '../Buttom/Window';

const DownloadButtom = () => {

  return (
    <>
      <div className="px-5"  >
        <div className="lg:mt-5 mt-8 ">
          <div class="flex justify-center mb-5">
            <div class="">
              <GetlinkHome />
            </div>
          </div>
          {/* <div className="lg:flex lg:justify-center lg:space-x-4 md:flex md:justify-center md:space-x-4 grid grid-rows-3 mb-8 " >
            <Googleplay />
            <AppleStoreButton/>
            <Window/>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default DownloadButtom