// BlogContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a context
const BlogContext = createContext();

// Create a provider component
const BlogProvider = ({ children }) => {
  // Define your context values and state here
  const [fix, setFix] = useState(false);

  return (
    <BlogContext.Provider value={{ fix, setFix }}>
      
      {children}
    </BlogContext.Provider>
  );
};

// Create a custom hook to access the context values
const useBlogContext = () => {
  return useContext(BlogContext);
};

export { BlogProvider, useBlogContext };
