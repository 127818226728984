import React, { useState } from 'react';
import { BsFillTelephoneInboundFill } from 'react-icons/bs';
import { GrMail } from 'react-icons/gr'


const ContactUsFooter = () => {

    return (
        <>
            <div className="mt-10  bg-sky-100 lg:flex lg:justify-center object-cover bg-cover bg-no-repeat  lg:items-center lg:space-x-14 lg:pt-10 pt-5">
                <div className="flex justify-center">
                    <img src="https://app.apnabillbook.com/uploads/1729937530122avatar_billbook.png" alt="avatar_billbook"
                        className='h-72'
                    />
                </div>
                <div className="lg:w-1/2 w-full text-center lg:text-start">
                    <div className="text-[25px] xl:text-[30px] lg:text-[30px]  text-black font-bold mt-2">
                        Want to know more?
                    </div>
                    <div className="text-[25px] xl:text-[30px] lg:text-[30px] text-black font-bold mt-0">
                        Get in touch!
                    </div>
                    <div className='text-[14px] xl:text-[15px] lg:text-[15px] lg:px-0 px-5 mt-2'>
                        Our experts are always willing to answer any questions you have, and help you get started with Apna Billbook. Contact us to know more about the product and how it can help you grow your retail business.
                    </div>
                    <div >

                        <div className="text-start pb-8 ">

                            <div className="lg:flex lg:justify-start md:flex md:justify-center space-x-5 mt-5  ">
                                <span className='lg:text-start text-center '>
                                    <h2 className="text-lg font-normal">Call us :</h2>
                                    <span className='flex lg:justify-start justify-center items-center space-x-1'>
                                        <BsFillTelephoneInboundFill className='text-lg font-medium text-[#0e7cf4]' />
                                        <p className="text-lg font-medium  text-[#0e7cf4]">+91 9258712434</p>
                                    </span>
                                    <p className="text-sm font-light  lg:w-56">Monday to Friday, 9 am to 6 pm</p>
                                </span>
                                <span>
                                    <div class="border-l border-dotted border-gray-500 h-24 hidden lg:block md:block "></div>
                                </span>
                                <span className='lg:text-start text-center '>
                                    <h2 className="text-lg font-normal">Email us :</h2>
                                    <span className='flex items-center justify-center space-x-1'>
                                        <GrMail className='text-lg font-medium text-[#0e7cf4]' />
                                        <p className="text-lg font-medium text-[#0e7cf4]">feedback@apnabillbook.com</p>
                                    </span>
                                    <p className="text-sm font-light">Anytime 24x7</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default ContactUsFooter;