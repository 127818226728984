import React from 'react';

const ApnaPagination = ({ currentPage, totalPage, onPageChange }) => {
  const pageNumbers = [];
  const maxPageNumbersToShow = 5; // Maximum number of page numbers to show
  const pageGap = '...'; // Text to represent gaps between page numbers

  for (let i = 1; i <= totalPage; i++) {
    pageNumbers.push(i);
  }

  // Function to generate page numbers with gaps
  const generatePageNumbersWithGaps = () => {
    const pageNumbersWithGaps = [];
    let lastPageNumber = 0;

    for (const pageNumber of pageNumbers) {
      if (pageNumber === 1 || pageNumber === totalPage || Math.abs(pageNumber - currentPage) <= maxPageNumbersToShow - 1) {
        if (lastPageNumber !== 0 && pageNumber - lastPageNumber > 1) {
          pageNumbersWithGaps.push(pageGap);
        }
        pageNumbersWithGaps.push(pageNumber);
        lastPageNumber = pageNumber;
      }
    }

    return pageNumbersWithGaps;
  };

  const displayedPageNumbers = generatePageNumbersWithGaps();

  return (
    <ul className="flex space-x-2">
      {currentPage > 1 && (
        <li>
          <button
            className="px-3 py-1 rounded-md bg-blue-500 text-white"
            onClick={() => onPageChange(currentPage - 1)}
          >
            Previous
          </button>
        </li>
      )}
      {displayedPageNumbers.map((pageNumber, index) => (
        <li key={index}>
          {pageNumber === pageGap ? (
            <span className="px-2 py-1">{pageNumber}</span>
          ) : (
            <button
              className={`px-3 py-1 rounded-md ${
                currentPage === pageNumber ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
              }`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          )}
        </li>
      ))}
      {currentPage < totalPage && (
        <li>
          <button
            className="px-3 py-1 rounded-md bg-blue-500 text-white"
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next
          </button>
        </li>
      )}
    </ul>
  );
};

export default ApnaPagination;
