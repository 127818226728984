import React from 'react'
import Googleplay from '../Buttom/Googleplay';
import Window from '../Buttom/Window';
import AppleStoreButton from '../Buttom/Applestore';

const ContentDialog = ({ isOpen, onClose, title }) => {

    const whatsappLink = () => {
        const phoneNumber = '+919258712434';
        const message = 'I need help in installing windows app';
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');
    };
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 z-50 lg:py-0 md:py-0 py-12 lg:px-20 md:px-10  lg:flex md:flex items-center justify-center shadow-lg">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="relative z-10  rounded-lg lg:p-8 md:p-8 p-4">
                <button
                    onClick={onClose}
                    className="absolute top-0 lg:right-0 right-7 text-white bg-black p-1 shadow-lg rounded-md"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 "
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>

                <div className="">
                    <div className="bg-white py-10 px-3 w-full rounded-lg mt-4">
                        {title ? (
                            <>
                                <div
                                    class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-[#49C95A]"
                                >
                                    <svg
                                        class="h-12 w-12 text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M5 13l4 4L19 7"
                                        ></path>
                                    </svg>
                                </div>

                                <div class="mt-6 px-10">
                                    <p class="text-sm text-gray-500 text-center">
                                        {title}
                                    </p>
                                </div>
                            </>

                        ) : (
                            <div className="lg:flex md:flex lg:flex-row md:flex-row lg:justify-between md:justify-between flex flex-col justify-center  space-x-5 items-center lg:px-6 md:px-6 px-3">
                                <div className="lg:w-1/2 md:w-1/2 ">
                                    <h1 className='text-[#0E7CF2] lg:text-5xl md:text-3xl text-xl font-serif font-[600]'>Billing Software For PC</h1>
                                    <h1 className='my-5 text-gray-600 text-base  font-serif font-[400]'>Create professional invoices in seconds, track income & expenses easily, and stay organized! Download Apna Billbook Now & Experience Faster Billing on PC with a Free Trial!</h1>
                                    <div className="lg:block md:block hidden">
                                        <div className=" lg:flex justify-center md:flex  lg:space-x-2 mt-6 lg:mb-0 mb-10 lg:ml-0 md:ml-0 
     " >
                                            <Googleplay />
                                            <AppleStoreButton />
                                            <Window />
                                        </div>
                                    </div>
                                    <div className=" lg:hidden md:hidden">
                                        <div className="  mt-6 lg:mb-0 mb-10 lg:ml-0 md:ml-0 
     " >
                                            <div className="flex justify-center space-x-2">
                                                <Googleplay />
                                                <AppleStoreButton />
                                            </div>
                                            <div className="flex justify-center">
                                                <Window />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-lg my-2'>
                                        <span className='text-black'> If you encounter any issues, please connect with us. - <span onClick={whatsappLink} className='cursor-pointer text-[#0E7CF2]'>Click Now!</span></span>
                                    </div>
                                </div>
                                <div className="lg:w-1/2 rounded-md shadow-sky-300 shadow-xl md:w-1/2">
                                    <img src="https://app.apnabillbook.com/uploads/1729974072632onlinestore.jpg" alt="onlinestore" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ContentDialog;