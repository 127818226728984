import React from 'react'
import Faq from './Faq'
import ContactUsFooter from '../Footer/ContactUsFooter'

const FaqContent = () => {
  return (
    <>
      <div className="">
        <Faq title="Frequently Asked Question's" />
      </div>
      <ContactUsFooter />
    </>
  )
}

export default FaqContent