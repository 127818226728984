import React, { useEffect, useState } from 'react'
import PageTitle from '../Section/PageTitel/Pagetitle'
import Googleplay from '../Buttom/Googleplay';
import Window from '../Buttom/Window';
import { BsDot } from "react-icons/bs";
import ProductCategory from '../ProductCategory/ProductCategory';
import Pricing from '../Pricing/Pricing';
import ContactUsFooter from '../Footer/ContactUsFooter';
import AppleStoreButton from '../Buttom/Applestore';

const OnlineStore = () => {
  const [fix, setFix] = useState(false);

  function addMetaData() {
    const title = "Cloud base Online Store";
    const description = "Expand your business with the Online Store feature in Apna Billbook, which lets you list your products on our platform and offer home delivery options. Manage sales, inventory, and payments seamlessly, all from a single application.";
    const ogImage = "https://app.apnabillbook.com/uploads/1729980230785Cloud Based Online Store.jpg";
    const ogURL = "https://apnabillbook.com/online-store";
    const twitterImage = "./twitter.jpg";
    const keywords = "Online store for small business, Online Store, online shopping experience, home delivery, Streamline operations, upselling opportunities, billing software with e-commerce, manage inventory and sales online​(Apna Billbook features)";
    document.title = title;

    const title65Char = title.substring(0, 65) ?? title;
    const description155Char = description.substring(0, 155) ?? description;

    // Set the meta tags
    document.querySelector('meta[name="title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="description"]').setAttribute("content", description155Char);
    document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
    document.querySelector('meta[property="og:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[property="og:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[property="og:image"]').setAttribute("content", ogImage);
    document.querySelector('meta[property="og:url"]').setAttribute("content", ogURL);
    document.querySelector('meta[name="twitter:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="twitter:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[name="twitter:image"]').setAttribute("content", twitterImage);

  }

  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    addMetaData()
    window.addEventListener("scroll", Navstick);
    return () => {
      window.removeEventListener("scroll", Navstick);
    };
  }, []);
  return (
    <>
      <div
        className={fix ? "w-full  bg-white" : " bg-white"}
      >
        <div className={`lg:px-16 border border-top-1 py-3 ${fix ? "fixed border border-top-1 shadow-sm bg-white w-full mt-16 z-50 " : " "
          }`}>
          <PageTitle
            activeMenu="Online Store"
          />
        </div>
      </div>
      <div className=" w-full " >
        <div className=" overflow-hidden relative  inset-0 ">
          <img src="https://app.apnabillbook.com/uploads/1729974072630Container.png" alt="Container" className="absolute  inset-0 w-full h-screen object-cover " />
          <div class="relative lg:px-20 md:px-14 px-6 py-6  lg:flex md:flex justify-between items-center space-x-4 lg:mt-10 mt-2 ">
            <div >
              <h3 className="text-white lg:text-5xl text-[23px] uppercase tracking-wider  xl:mb-2">
                <h1 class="lg:space-y-8"> Online Store, made Easy
                </h1>
              </h3>
              <div class="lg:mt-10  mt-3  text-white tracking-wider text-base  lg:text-2xl ">

                <h2>
                  <span>
                    Easily track your inventory, know which items sell fast, which items expire soon. Invest in the right inventory to maximize your profits..</span>
                </h2>
                <div className="lg:my-10 md:my-5 my-3 lg:flex  lg:space-x-4 md:flex md:space-x-4 grid grid-cols-2 gap-3 mb-8 " >
                  <Googleplay />
                  <AppleStoreButton />
                  <Window />
                </div>
              </div>
            </div>
            <div className="">
              <div className=" flex justify-center  w-full z-10">
                <div className="flex justify-center">

                  <div className="mt-2 
                    items-center  mx-10 -translate-x-2 -rotate-0 rounded-3xl bg-white p-1">
                    <div className=" translate-x-0 rotate-0 overflow-hidden rounded-3xl shadow-lg transition-transform hover:scale-100 ">
                      <video autoPlay muted loop className="h-full w-full  object-cover rounded-3xl"
                        poster="https://app.apnabillbook.com/getstarted.jpg"
                      >
                        <source src="https://app.apnabillbook.com/getstarted.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="bg-sky-50 py-16 mx-auto container lg:px-20 md:px-16 px-5">
        <div className="lg:flex md:flex justify-between items-center lg:space-x-10 md:space-x-8">
          <div className="lg:w-1/2 md:w-1/2">
            <img
              src="https://app.apnabillbook.com/uploads/1729980230785Cloud Based Online Store.jpg"
              alt="online store"
              className="rounded-lg shadow-md"
            />
          </div>
          <div className="lg:w-1/2 md:w-1/2 lg:mt-0 md:mt-0 mt-3">
            <h1 className="text-3xl font-bold text-[#0E7CF2] mb-6">
              Cloud Based Online Store
            </h1>
            <div className="">
              <div className="">
                <p className="text-gray-700">
                  Expand your business with the Online Store feature in Apna Billbook, which lets you list your products on our platform and offer home delivery options. Manage sales, inventory, and payments seamlessly, all from a single application.
                </p>
                <h2 className="lg:text-xl text-lg font-serif font-semibold  mt-8 mb-4">
                  Benefits:
                </h2>
                <ul className="list-disc  ">
                  <li className="flex  items-center mb-2">
                    < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                    Increase revenue by reaching a larger customer base.
                  </li>
                  <li className="flex  items-center mb-2">
                    < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                    Improve customer satisfaction with a smooth online shopping experience.
                  </li>
                  <li className="flex  items-center mb-2">
                    < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                    Streamline operations by managing everything in one platform.
                  </li>
                  <li className="flex  items-center mb-2">
                    < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                    Boost sales with upselling opportunities by activating your online store mode.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <h2 className="text-xl font-semibold font-serif mt-8 mb-4">
            Benefits of Online Store Feature
          </h2>
          <ul className="list-disc ">
            <li className="flex  items-center ">
              < BsDot className=" text-[#0E7CF2] rounded-full text-2xl " />
              < h1 className=" text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5" >Increased Revenue:</h1>
            </li>
            Offering products or services directly through the billing application canleadtoadditional sales opportunities, as customers are already engaged with the brand.

            <li className="flex  items-center mt-4">
              < BsDot className=" text-[#0E7CF2] rounded-full text-2xl " />
              < h1 className=" text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5" >Enhanced Customer Experience:</h1>
            </li>
            Customers can easily purchase items or services without havingtonavigate to a separate online store. This streamlined process can improve customer satisfaction.

            <li className="flex  items-center mt-4">
              < BsDot className=" text-[#0E7CF2] rounded-full text-2xl " />
              < h1 className=" text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5" >Improved Efficiency:</h1>
            </li>
            Managing sales, inventory, and payments within a single platformcan reduceadministrative overhead and improve operational efficiency.

            <li className="flex  items-center mt-4">
              < BsDot className=" text-[#0E7CF2] rounded-full text-2xl " />
              < h1 className=" text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5" >Upselling Opportunities:</h1>
            </li>
            By turning the store to online mode owner can increase average order
            values and drive additional sales.

          </ul>
        </div>
      </div>
      <div className="">
        <ProductCategory />
      </div>
      <div className="">
        <Pricing />
      </div>

      <ContactUsFooter />
    </>
  )
}

export default OnlineStore;