import React, { useEffect, useState } from 'react'
import PageTitle from '../Section/PageTitel/Pagetitle'
import Googleplay from '../Buttom/Googleplay';
import Window from '../Buttom/Window';
import { BsDot } from 'react-icons/bs';
import ProductCategory from '../ProductCategory/ProductCategory';
import Pricing from '../Pricing/Pricing';
import ContactUsFooter from '../Footer/ContactUsFooter';
import AppleStoreButton from '../Buttom/Applestore';

const ReportsAnalytics = () => {
  const [fix, setFix] = useState(false);

  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  function addMetaData() {
    const title = "Reports & Analytics – Get Actionable Business Insights";
    const description = "Maximize your business potential with ApnaBillBook’s Reports & Analytics tools, designed to provide real-time insights into your sales, inventory, and expenses. With comprehensive data at your fingertips, you can make informed decisions to drive growth and profitability.";
    const ogImage = "https://app.apnabillbook.com/uploads/1729980230804Report Analysis.jpg";
    const ogURL = "https://apnabillbook.com/reports-analytics";
    const twitterImage = "./twitter.jpg";
    const keywords = " Business reports tool, billing analytics software, data-driven billing insights​(Apna Billbook features) ,Reports & Analytics tools, sales, inventory, and expenses, sales performance, inventory levels, profit & loss, GST-compliant reports,Sign Up Now, Customizable Reports, Real-Time Sales Tracking, Inventory Analytics, GST Reports, Sign Up for Free, Generate Reports, Analyze Data";
    document.title = title;

    const title65Char = title.substring(0, 65) ?? title;
    const description155Char = description.substring(0, 155) ?? description;

    // Set the meta tags
    document.querySelector('meta[name="title"]').setAttribute("content", title65Char);

    document.querySelector('meta[name="description"]').setAttribute("content", description155Char);
    document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
    document.querySelector('meta[property="og:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[property="og:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[property="og:image"]').setAttribute("content", ogImage);
    document.querySelector('meta[property="og:url"]').setAttribute("content", ogURL);
    document.querySelector('meta[name="twitter:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="twitter:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[name="twitter:image"]').setAttribute("content", twitterImage);

  }

  useEffect(() => {
    addMetaData()
    window.addEventListener("scroll", Navstick);
    return () => {
      window.removeEventListener("scroll", Navstick);
    };
  }, []);
  return (
    <>
      <div
        className={fix ? "w-full  bg-white" : " bg-white"}
      >
        <div className={`lg:px-16 border border-top-1 py-3 ${fix ? "fixed border border-top-1 shadow-sm bg-white w-full mt-16 z-50 " : " "
          }`}>
          <PageTitle
            activeMenu="Reports Analytics"
          />
        </div>
      </div>
      <div className=" w-full " >
        <div className=" overflow-hidden relative  inset-0 ">
          <img src="https://app.apnabillbook.com/uploads/1729974072630Container.png" alt="Container" className="absolute  inset-0 w-full h-screen object-cover " />
          <div class="relative lg:px-20 md:px-14 px-6 py-6  lg:flex md:flex justify-between items-center space-x-4 lg:mt-10 mt-2 ">
            <div >
              <h3 className="text-white lg:text-5xl text-[23px] uppercase tracking-wider  xl:mb-2">
                <h1 class="lg:space-y-8"> Reports Analytics, made Easy
                </h1>
              </h3>
              <div class="lg:mt-10  mt-3  text-white tracking-wider text-base  lg:text-2xl ">

                <h2>
                  <span>
                    Easily track your inventory, know which items sell fast, which items expire soon. Invest in the right inventory to maximize your profits..</span>
                </h2>
                <div className="lg:my-10 md:my-5 my-3 lg:flex  lg:space-x-4 md:flex md:space-x-4 grid grid-cols-2 gap-3 mb-8 " >
                  <Googleplay />
                  <AppleStoreButton />
                  <Window />
                </div>
              </div>
            </div>
            <div className="">
              <div className=" flex justify-center  w-full z-10">
                <div className="flex justify-center">

                  <div className="mt-2 
                    items-center  mx-10 -translate-x-2 -rotate-0 rounded-3xl bg-white p-1">
                    <div className=" translate-x-0 rotate-0 overflow-hidden rounded-3xl shadow-lg transition-transform hover:scale-100 ">
                      <video autoPlay muted loop className="h-full w-full  object-cover rounded-3xl"
                        poster="https://app.apnabillbook.com/getstarted.jpg"
                      >
                        <source src="https://app.apnabillbook.com/getstarted.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="bg-sky-50 py-16 mx-auto container lg:px-20 md:px-16 px-5">
        <div className="lg:flex md:flex justify-between items-center lg:space-x-10 md:space-x-8">
          <div className="lg:w-1/2 md:w-1/2">
            <img
              src="https://app.apnabillbook.com/uploads/1729980230804Report Analysis.jpg"
              alt="Report Analysis"
              className="rounded-lg shadow-md"
            />
          </div>
          <div className="lg:mt-0 md:mt-0 mt-3 lg:w-1/2 md:w-1/2">
            <h1 className="text-3xl font-bold text-[#0E7CF2] mb-6">
           Reports & Analytics – Get Actionable Business Insights
            </h1>
            <div className="">
              <div className="">
                <p className="text-gray-700">
                  Maximize your business potential with ApnaBillBook’s Reports & Analytics tools, designed to provide real-time insights into your sales, inventory, and expenses. With comprehensive data at your fingertips, you can make informed decisions to drive growth and profitability.
                </p>

              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
          <h2 className="text-xl font-semibold font-serif mt-8 mb-4">
            Key Features:
          </h2>
          <ul className="list-disc">
            <li className="flex items-center">
              <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
              <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                Customizable Reports:
              </h1>
            </li>
            <p className="pl-6">
              Generate custom reports on sales performance, inventory levels, and profit & loss statements.
            </p>

            <li className="flex items-center mt-4">
              <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
              <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                Real-Time Sales Tracking:
              </h1>
            </li>
            <p className="pl-6">
              Monitor daily, weekly, and monthly sales in real time to better understand your business performance.
            </p>

            <li className="flex items-center mt-4">
              <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
              <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                Inventory Analytics:
              </h1>
            </li>
            <p className="pl-6">
              Keep track of stock movements, identify best-selling products, and make smarter purchasing decisions.
            </p>


            <li className="flex items-center mt-4">
              <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
              <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                GST Reports:
              </h1>
            </li>
            <p className="pl-6">
              Automatically generate GST-compliant reports for easy tax filing and compliance with Indian regulations.</p>
          </ul>
        </div>
        <div className="mb-5">
          <h2 className="text-xl font-semibold font-serif mt-8 mb-4">
            How It Works:
          </h2>
          <ul className="list-disc">
            <li className="flex items-center">
              <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
              <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                Sign Up for Free:
              </h1>
            </li>
            <p className="pl-6">
              Access all reports and analytics by registering with ApnaBillBook.
            </p>

            <li className="flex items-center mt-4">
              <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
              <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                Generate Reports:
              </h1>
            </li>
            <p className="pl-6">
              Customize reports based on your business needs, from sales to inventory and expenses.
            </p>

            <li className="flex items-center mt-4">
              <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
              <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                Analyze Data:
              </h1>
            </li>
            <p className="pl-6">
              Use real-time insights to improve decision-making, manage resources, and grow your business.
            </p>
          </ul>
        </div>
        <div className="">
          <h2 className="text-xl font-semibold font-serif mt-8 mb-4">
          Make Smarter Business Decisions
          </h2>
          <p className="">
          Access detailed business reports anytime, anywhere. Sign Up Now to start using ApnaBillBook Reports & Analytics!
          </p>
        </div>

      </div>
      <div className="">
        <ProductCategory />
      </div>
      <div className="">
        <Pricing />
      </div>

      <ContactUsFooter />
    </>
  )
}

export default ReportsAnalytics;