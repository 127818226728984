import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const PageTitle = ({ motherMenu, activeMenu }) => {
 

  return (
    <div className="container mx-auto">
      <div className="dlab-bnr-inr-entry text-center">
        {/* <h1 className="text-white">{motherMenu}</h1> */}
      </div>
      <ul className="flex lg:pl-0 pl-6 space-x-3 items-center">
        <li className="text-[#000F37] hover:scale-105 duration-150">
          <Link to={"/"} className="">
            Home
          </Link>
        </li>
        <MdOutlineKeyboardArrowRight className="text-[#000F37]" />
        <li className="text-[#000F37]">{activeMenu}</li>
      </ul>
    </div>
  );
};

export default PageTitle;
