import React, { useEffect, useState } from 'react';
import { BsFillBox2Fill, BsFillCalculatorFill } from 'react-icons/bs';
import { MdOutlineStorefront } from 'react-icons/md';
import { AiOutlineSound } from 'react-icons/ai';
import { SiGoogleanalytics } from 'react-icons/si';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom'
import bill from '../../Assest/icons/IMG_5329.PNG'
import storeFront from '../../Assest/icons/IMG_5326.PNG'
import inventory from '../../Assest/icons/IMG_5323.PNG'
import debt from '../../Assest/icons/IMG_5329.PNG'
import report from '../../Assest/icons/IMG_5325.PNG'
import calculator from '../../Assest/icons/Online Store.PNG'

const Features = () => {
    var settings = {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 2000,
        dots: true,
        useCss: true,
        dotsClass: 'slick-dots ${style.dots}',
        navSpeed: 2000,
        infinite: true,
        autoplay: true,
        centerMode: true, // Center the images
        centerPadding: '0', // No padding around centered images
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };


    const feat = [
        {
            icon: <BsFillBox2Fill />,
            image: bill,
            title: "Quick Billing",
            description: "Simplifies the process of creating, printing, and sharing bills, receipts, and GST invoices 🧾, providing you with the flexibility to personalize them with your company branding, resulting in polished and customized documents for your business needs. 📜🖨️"
        },
        {
            icon: <BsFillCalculatorFill />,
            title: "Online Store",
            image: storeFront,
            description: "The Online Store feature enables you to swiftly and effortlessly establish a digital storefront 🏪. It empowers your business to have a continuous online presence, allowing customers to browse and purchase your products or services from anywhere, 24/7 🌐🛒."
        },
        {
            icon: <BsFillBox2Fill />,
            title: "Inventory Management",
            image: inventory,
            description: "Simplifies product organization by allowing you to create an unlimited number of products, categorize items 📦, and track variants 🔄. This feature helps you maintain a well-organized product catalog, making it easier to find and manage your products efficiently. 🛍️"
        },
        {
            icon: <MdOutlineStorefront />,
            title: "Customer Udhaar, Ledger,Feedback",
            image: debt,
            description: "Efficiently tracking and managing customer debts through prompt recording and reminders enhances 💰 cash flow, 🏦 financial stability, 📈 transparency, and 🤝 trust for stronger, long-term customer relationships."
        },
        {
            icon: <SiGoogleanalytics />,
            image: report,
            title: "Reports & Analytics",
            description: "Gain comprehensive business insights 📊, enabling data-driven strategic decisions 📈, performance evaluation 📉, and simplified GST compliance 📑 for effective financial planning and growth 🌱. Enhance your competitive edge with actionable analytics 📈📊."
        },
        {
            icon: <AiOutlineSound />,
            image: calculator,
            title: "Expense Management",
            description: "Simplify expense tracking with an intuitive interface 📊. Easily record, categorize, and stay on top of expenses, ensuring accurate financial records 📑. Gain a comprehensive view of your business's spending patterns 💼, and make informed financial decisions. 💰"
        },

    ];

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="container mx-auto lg:mt-20 my-20 hidden md:block">
                {/* <h1 className='flex justify-center mb-20 text-[#313131] lg:text-[70px] text-5xl  font-bold'>Features</h1> */}
                <div className="lg:px-20 md:px-14 px-8 lg:mt-10  xl:grid xl:grid-cols-3 lg:grid lg:grid-cols-2 grid md:grid-cols-2 gap-20 ">
                    {feat.map((feature, index) => (
                        <div key={index} className="bg-sky-50 pb-8 px-6 rounded-3xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
                            <div className="text-4xl text-center flex justify-center items-center px-5 relative ">
                                <span class="absolute  left-0 top-0 ">
                                </span>
                                <img src={feature.image} alt=""
                                    className='w-60 h-60 md:w-55 md:h-55 sm:w-50 sm:h-50'
                                />

                            </div>

                            <div className=' text-[#0e7cf2] lg:text-2xl leading-tight text-3xl mb-2 mt-4 font-[600]'>{feature.title}</div>
                            <div className='mt-0 lg:text-[13px] text-[black]  text-lg'>{feature.description}</div>

                        </div>

                    ))}
                </div>
                <div id="about" ></div>
                <Link to="/feature" class="text-[#575757] "
                ><div className='flex justify-center mt-10'>
                        <button
                            className='rounded-lg buttonbg text-lg py-3 mt-5
                     text-white hover:scale-105 px-7 text-center text-[14px]
                    '>
                            Explore All Features
                        </button>
                    </div>
                </Link>
            </div>

            {/* // gallery with dots */}

            <div className="container mx-auto lg:mt-0 my-0 md:hidden bg-sky-100 items-center">
                <Slider
                    className={`transition-transform duration-200 bg-sky-100
                ease-in-out transform-gpu ${scrollY > 0 ? '-translate-y-0' : ''
                        }`}
                    {...settings}
                >

                    {feat.map((feature, index) => (
                        <div key={index} className="bg-sky-100 py-8 px-6 mt-2
                    transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 items-center">
                            <div className="text-center items-center">
                                <span class="absolute  left-0 top-0">
                                </span>
                                <img src={feature.image} alt=""
                                    className='mx-auto'
                                    width="100px"
                                    height="100px"
                                />
                            </div>

                            <h1 className='mt-1 text-[#0e7cf2] lg:text-3xl text-[16px] mb-1 font-[600] text-center'>{feature.title}</h1>
                            <p className='mt-0  lg:text-[16px] text-[#575757] text-[12px] px-5 text-center'>{feature.description}</p>

                        </div>
                    ))}

                </Slider>

                <Link to="/feature" class="text-[#575757] "
                ><div className='flex justify-center'>
                        <button
                            className='rounded-lg buttonbg text-lg py-3 mt-5
                     text-white hover:scale-105 px-7 text-center text-[14px]
                    '>
                            Explore All Features
                        </button>
                    </div>
                </Link>
            </div>



        </>
    );
}

export default Features;
