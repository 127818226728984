import React, { useState, useEffect } from 'react'
import PageTitle from './PageTitel/Pagetitle';
import Getstarted from './Getstarted';

const Privacy = () => {
  const [fix, setFix] = useState(false);

  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", Navstick);
    return () => {
      window.removeEventListener("scroll", Navstick);
    };
  }, []);
  const phoneNumber = '9811239321'; // Replace with the actual phone number
  const message = encodeURIComponent('Hello, this is a WhatsApp message!');

  const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

  const emailAddress = 'info@apnabillbook.com'; // Replace with the actual email address
  const subject = encodeURIComponent('Subject of the email');
  const body = encodeURIComponent('Hello, this is the body of the email.');

  const gmailLink = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
  return (
    <>
      <div
        className={fix ? "w-full  bg-white" : " bg-white"}
      >

        <div className={`lg:px-16 border border-1 py-3 ${fix ? "fixed border border-1 bg-white w-full mt-16  z-50 " : " "
          }`}>
          <PageTitle
            activeMenu="Privacy Policy"
          />
        </div>
      </div>
      <div className="mb-14">
        <div className="">
          <div className="container mx-auto px-20">

            <h2 className='text-5xl font-semibold pt-12 pb-6 text-[#000F37]'>Privacy Policy for Business Sales Management, Expense Tracking, Inventory Management, Customer Relationship Management, and Ledger Management</h2>
            <p className='text-lg'>At Apna Billbook, we are committed to protecting the privacy and security of our clients' and customers' personal information. This Privacy Policy outlines how we collect, use, and disclose personal information in the course of providing our sales management, expense tracking, inventory management, customer relationship management, and ledger management services.
            </p>
            <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>1. Collection of Personal Information</h2>
            <p className='text-lg mt-3'>We may collect personal information from you or your clients in the course of providing our services. This may include name, contact information, financial information, and other information necessary to provide our services</p>
            <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>2. Use of Personal Information</h2>
            <p className='text-lg mt-3'>We use personal information to provide and improve our services, as well as to communicate with clients and customers regarding our services. We may also use personal information to comply with legal obligations and enforce our policies.</p>
            <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>3. Disclosure of Personal Information</h2>
            <p className='text-lg mt-3'>We may disclose personal information to third-party service providers who assist us in providing our services. We may also disclose personal information to comply with legal obligations or respond to lawful requests from government authorities.</p>
            <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>4. Security of Personal Information</h2>
            <p className='text-lg mt-3'>We take reasonable measures to protect personal information from unauthorized access, disclosure, or destruction. However, no data transmission or storage system can be guaranteed to be 100% secure.</p>
            <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>5. Retention of Personal Information</h2>
            <p className='text-lg mt-3'>We will retain personal information for as long as necessary to provide our services and fulfill our legal obligations. After this time, we will securely destroy or anonymize personal information.</p>
            <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>6. Your Rights</h2>
            <p className='text-lg mt-3'>You have the right to access, correct, or delete personal information we hold about you. You may also have the right to object to or restrict certain uses of your personal information. To exercise these rights, please contact us using the information provided below.</p>
            <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>7. Changes to this Privacy Policy` </h2>
            <p className='text-lg mt-3'>We may update this Privacy Policy from time to time. We will notify clients and customers of any material changes to this Privacy Policy.</p>
            <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>8. Contact Us</h2>
            <p className='text-lg mt-3 mb-7'>If you have any questions or concerns about this Privacy Policy or our use of personal information, please contact us at <a href={gmailLink} className='text-sky-700 cursor-pointer'> info@apnabillbook.com </a>  or call us at <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className='text-sky-700 cursor-pointer'> <br />+91-9811239321 </a>.</p>
          </div>
        </div>
      </div>
      <Getstarted />
    </>
  )
}

export default Privacy
// and having corporate identity number L45101DL2006PLC148314