import React, { useEffect, useState } from 'react'
import PageTitle from '../Section/PageTitel/Pagetitle'
import Googleplay from '../Buttom/Googleplay';
import Window from '../Buttom/Window';
import { BsDot } from "react-icons/bs";
import ProductCategory from '../ProductCategory/ProductCategory';
import Pricing from '../Pricing/Pricing';
import ContactUsFooter from '../Footer/ContactUsFooter';
import AppleStoreButton from '../Buttom/Applestore';

const InventoryManagment = () => {
    const [fix, setFix] = useState(false);

    const Navstick = () => {
        if (window.scrollY >= 30) {
            setFix(true);
        } else {
            setFix(false);
        }
    };

    function addMetaData() {
        const title = "Cloud base Inventory Managment";
        const description = "Effortlessly manage your inventory with Apna Billbook’s Inventory Management Software, designed to provide real-time stock updates across multiple platforms—whether sales are made online or in-store. Reduce errors and streamline your operations with automated inventory management, bulk uploads, and a barcode scanner feature for quick product additions.";
        const ogImage = "https://app.apnabillbook.com/uploads/1729980230798Inventory Management.jpg";
        const ogURL = "https://apnabillbook.com/inventory-managment";
        const twitterImage = "./twitter.jpg";
        const keywords = "Inventory management software, real-time stock updates, bulk product upload, barcode scanner inventory tool​(Apna Billbook features),ready-made catalogue,Track inventory";
        
    
        const title65Char = title.substring(0, 65) ?? title;
        const description155Char = description.substring(0, 155) ?? description;
        document.title = title65Char;
        // Set the meta tags
        document.querySelector('meta[name="title"]').setAttribute("content", title65Char);
        document.querySelector('meta[name="description"]').setAttribute("content", description155Char);
        document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
        document.querySelector('meta[property="og:title"]').setAttribute("content", title65Char);
        document.querySelector('meta[property="og:description"]').setAttribute("content", description155Char);
        document.querySelector('meta[property="og:image"]').setAttribute("content", ogImage);
        document.querySelector('meta[property="og:url"]').setAttribute("content", ogURL);
        document.querySelector('meta[name="twitter:title"]').setAttribute("content", title65Char);
        document.querySelector('meta[name="twitter:description"]').setAttribute("content", description155Char);
        document.querySelector('meta[name="twitter:image"]').setAttribute("content", twitterImage);
    
      }

    useEffect(() => {
        addMetaData()
        window.addEventListener("scroll", Navstick);
        return () => {
            window.removeEventListener("scroll", Navstick);
        };
    }, []);
    return (
        <>
            <div
                className={fix ? "w-full  bg-white" : " bg-white"}
            >
                <div className={`lg:px-16 border border-top-1 py-3 ${fix ? "fixed border border-top-1 shadow-sm bg-white w-full mt-16 z-50 " : " "
                    }`}>
                    <PageTitle
                        activeMenu="Inventory Managment"
                    />
                </div>
            </div>
            <div className=" w-full " >
                <div className=" overflow-hidden relative  inset-0 ">
                    <img src="https://app.apnabillbook.com/uploads/1729974072630Container.png" alt="Container" className="absolute  inset-0 w-full h-screen object-cover " />
                    <div class="relative lg:px-20 md:px-14 px-6 py-6  lg:flex md:flex justify-between items-center space-x-4 lg:mt-10 mt-2 ">
                        <div >
                            <h3 className="text-white lg:text-5xl text-[23px] uppercase tracking-wider  xl:mb-2">
                                <h1 class="lg:space-y-8"> Inventory Managment, made Easy
                                </h1>
                            </h3>
                            <div class="lg:mt-10  mt-3  text-white tracking-wider text-base  lg:text-2xl ">

                                <h2>
                                    <span>
                                        Easily track your inventory, know which items sell fast, which items expire soon. Invest in the right inventory to maximize your profits..</span>
                                </h2>
                                <div className="lg:my-10 md:my-5 my-3 lg:flex  lg:space-x-4 md:flex md:space-x-4 grid grid-cols-2 gap-3 mb-8 " >
                                    <Googleplay />
                                    <AppleStoreButton />
                                    <Window />
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className=" flex justify-center  w-full z-10">
                                <div className="flex justify-center">

                                    <div className="mt-2 
                    items-center  mx-10 -translate-x-2 -rotate-0 rounded-3xl bg-white p-1">
                                        <div className=" translate-x-0 rotate-0 overflow-hidden rounded-3xl shadow-lg transition-transform hover:scale-100 ">
                                            <video autoPlay muted loop className="h-full w-full  object-cover rounded-3xl"
                                                poster="https://app.apnabillbook.com/getstarted.jpg"
                                            >
                                                <source src="https://app.apnabillbook.com/getstarted.mp4" type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-sky-50 py-16 mx-auto container lg:px-20 md:px-16 px-8">
                <div className="lg:flex md:flex justify-between items-center lg:space-x-10 md:space-x-8">
                    <div className="lg:w-1/2 md:w-1/2">
                        <img
                            src="https://app.apnabillbook.com/uploads/1729980230798Inventory Management.jpg"
                            alt="Inventory"
                            className="rounded-lg shadow-md"
                        />
                    </div>
                    <div className="lg:w-1/2 md:w-1/2 lg:mt-0 md:mt-0 mt-3">
                        <h1 className="lg:text-3xl  text-2xl font-bold text-[#0E7CF2] lg:mb-6 md:mb-4 my-5 ">
                            Cloud base Inventory Management
                        </h1>
                        <div className="">
                            <div className="">
                                <p className="text-gray-700">
                                Effortlessly manage your inventory with Apna Billbook’s Inventory Management Software, designed to provide real-time stock updates across multiple platforms—whether sales are made online or in-store. Reduce errors and streamline your operations with automated inventory management, bulk uploads, and a barcode scanner feature for quick product additions.
                                </p>
                                <h2 className="lg:text-xl text-lg font-serif font-semibold  mt-8 mb-4">
                                Key Features:
                                </h2>
                                <ul className="list-disc  ">
                                    <li className="flex  items-center mb-2">
                                        < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                                       Automated real-time updates for inventory.
                                    </li>
                                    <li className="flex  items-center mb-2">
                                        < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                                        Choose from over 10,000 products in our ready-made catalogue.
                                    </li>
                                    <li className="flex  items-center mb-2">
                                        < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                                        Save time with bulk uploads via Excel.
                                    </li>
                                    <li className="flex  items-center mb-2">
                                        < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                                        Track inventory easily with our barcode scanner.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-6">
                    <h2 className="lg:text-xl text-lg font-serif font-semibold  mt-8 mb-4">
                        How To Manage Inventory using Apna Billbook?
                    </h2>
                    <p className="text-gray-700 my-4">
                        The practice of manually updating inventories is no longer relevant. Regardless of the size of your
                        inventory, Apna Billbook's automated onboarding procedure will help you prevent human error andsave a tonne of man-hours
                    </p>
                    <ul className="  ">
                        <li className="mb-4">
                            < h1 className="mr-3 text-[#0E7CF2] font-serif font-semibold text-2xl mb-3" >Readymade catalogue</h1>
                            Add products to inventory within seconds. Choose from over 10,000 products on our readymade
                            catalogue in Apna Billbook, the best-in-class inventory management app.
                        </li>
                        <li className=" mb-4">
                            < h1 className=" text-[#0E7CF2] font-serif font-semibold text-2xl mb-3" >Bulk Upload</h1>
                            Add products in bulk directly through the excel sheets and save you valuable time.
                        </li>
                        <li className=" mb-4">
                            < h1 className=" text-[#0E7CF2] font-serif font-semibold text-2xl mb-3" >Simple product form</h1>
                            Our simple product form makes it amazingly easy to add new products which are not listed on your
                            catalogue.
                        </li>
                        <li className=" mb-4">
                            < h1 className=" text-[#0E7CF2] font-serif font-semibold text-2xl mb-3" >Barcode scanner</h1>
                            You can also scan the barcode if you prefer. Use the built-in scanner for a smooth and speedy additionof products to your inventory!
                        </li>
                    </ul>
                </div>
                <div className="my-6">
                    <h2 className="lg:text-xl text-lg font-serif font-semibold  mt-8 mb-4">
                        For your inventory management requirements, Apna Billbook is a great choice for these threereasons:
                    </h2>
                    <ul className="list-disc  ">
                        <li className="flex  items-center mb-2">
                            < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                            Track and find stock in a matter of seconds.
                        </li>
                        <li className="flex  items-center mb-2">
                            < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                            Updates to inventory in real time.
                        </li>
                        <li className="flex  items-center mb-2">
                            < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                            Saves cost, time and effort by eliminating manual work.
                        </li>
                        <li className="flex  items-center mb-2">
                            < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                            Get stock reports to identify best-selling products.
                        </li>
                        <li className="flex  items-center mb-2">
                            < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                            updates inventory through a variety of online and offline sources.
                        </li>
                        <li className="flex  items-center mb-2">
                            < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                            Automatic product identification with a barcode scanner.
                        </li>
                    </ul>
                </div>
            </div>
            <div className="">
                <ProductCategory />
            </div>
            <div className="">
                <Pricing />
            </div>

            <ContactUsFooter />
        </>
    )
}

export default InventoryManagment