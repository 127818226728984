import React, { useState, useEffect } from 'react';
import { BsDownload } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import ContentDialog from '../DialogBox/ContentDialog';

const Download = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false)
  const navigate = useNavigate();

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  // Add scroll event listener on component mount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleDownloadClick = () => {
    setPopupOpen(true)
  };

  const handleDownloadClose = () => {
    setPopupOpen(false)
  };



  return (
    <>
    <div className='flex justify-between items-center lg:space-x-4'>
      <div className={` ${isScrolled ? 'buttonbg text-white py-3 px-2 rounded-lg buttom-color' : 'bg-white text-[#000F37] py-3 px-2 rounded-lg buttom-color'} `}>
        <div className={` ${isScrolled ? 'text-white' : 'text-[#000F37]'}`}>
          <div className="text-center">
            <Link to='/contact-us'>
              <button>
                Book A Demo
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className={` ${isScrolled ? 'buttonbg text-white py-3 px-3 rounded-lg buttom-color hidden lg:block xl:block' : 'bg-white text-[#000F37] py-3 px-5 rounded-lg buttom-color hidden lg:block xl:block'} `}
        onClick={handleDownloadClick}
      >
        <div className={`hidden w-full lg:block ${isScrolled ? 'text-white' : 'text-[#000F37]'}`}>
          <div className="flex justify-between items-center space-x-2">
            <BsDownload />
            <button >
              Download Now
            </button>
          </div>
        </div>
      </div>
    </div>
    {popupOpen && (
      <ContentDialog  isOpen={handleDownloadClick} onClose={handleDownloadClose}/>
    )}
    </>
  );
};

export default Download;
