import React, { useState, useRef,useEffect } from 'react';
import JoditEditor from 'jodit-react';

export default function DraftEditor  ( props) {
	const editor = useRef(null);
	const [content, setContent] = useState('');

	const config = {
		readonly: false,
		
	};

  useEffect(()=>{
    props.handleContentChange(content)
  },[content])

	return (
		<JoditEditor
			ref={editor}
			value={content}
			config={config}
			tabIndex={1}
			onBlur={newContent => setContent(newContent)}
		/>
	);
};


