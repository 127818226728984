import React, { useState, useEffect } from 'react'
import PageTitle from './PageTitel/Pagetitle';
import Getstarted from './Getstarted';

const RefundPolicy = () => {
    const [fix, setFix] = useState(false);
    const [currentDate, setCurrentDate] = useState('');

    const Navstick = () => {
        if (window.scrollY >= 30) {
            setFix(true);
        } else {
            setFix(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", Navstick);
        return () => {
            window.removeEventListener("scroll", Navstick);
        };
    }, []);

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString();
        setCurrentDate(formattedDate);
      }, []);      

      const handleEmailClick = () => {
        window.location.href = 'mailto:feedback@apnabillbook.com';
      };
    return (
        <>
            <div
                className={fix ? "w-full  bg-white" : " bg-white"}
            >
                <div className={`lg:px-16 border border-1 py-3 ${fix ? "fixed border border-1 bg-white w-full mt-16 z-50 " : " "
                    }`}>
                    <PageTitle
                        activeMenu="Terms & Conditions"
                    />
                </div>
            </div>
            <div className="mb-14">
                <div className="">
                    <div className="container mx-auto px-20">
                        <h2 className='text-5xl font-semibold pt-12 pb-4 text-[#000F37]'>Refund Policy</h2>
                        <div className="">
                           <span className='text-xl font-semibold mt-5 text-[#000F37]'> Date : </span>{currentDate}
                        </div>
                        <div className="">
                           <span className='text-xl font-semibold mt-5 text-[#000F37]'> Company Address : </span>183 Shanti Bhawan Road Kukra Road Mahadev Property Dealer Gandhi Nagar Muzaffarnagar Uttar Pradesh 251001
                        </div>
                        <div className="">
                           <span className='text-xl font-semibold mt-5 text-[#000F37]'> Contact Email : </span>feedback@apnabillbook.com.
                        </div>
                        <div className="">
                           <span className='text-xl font-semibold mt-5 text-[#000F37]'> Contact Phone Number : </span>+91 98112 39321
                        </div>
                        <div className="text-xl font-semibold mt-5 text-[#000F37]">
                        Dear Valued Customer,
                        </div>
                        <p className='text-lg'>We appreciate your trust in<span className='text-lg font-semibold mt-5 text-[#000F37]'> Apna Softwares </span> and the use of our<span className='text-lg font-semibold mt-5 text-[#000F37]'> Apna Billbook</span> Android app and web app. We strive to provide the best possible service to our users.
                        </p>
                        <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>Refund Policy:</h2>
                        <h2 className='text-2xl font-semibold mt-5 text-[#000F37]'>1. Subscription Refunds:</h2>
                        <p className='text-lg mt-3'>
                        <span className=' font-semibold mt-5 text-[#000F37]'>Refunds are not allowed.</span> Once a subscription plan is purchased, the payment is non-refundable.
                        </p>
                        <h2 className='text-2xl font-semibold mt-5 text-[#000F37]'>2. No Exceptions:</h2>
                        <p className='text-lg mt-3'>Please note that regardless of the circumstances, we do not entertain refund requests for subscription fees.</p>
                        <h2 className='text-2xl font-semibold mt-5 text-[#000F37]'>3. Trial Period:</h2>
                        <p className='text-lg mt-3'>We encourage users to take advantage of any trial periods offered before committing to a subscription plan to ensure the app meets their needs.</p>
                        <h2 className='text-2xl font-semibold mt-5 text-[#000F37]'>4. Cancellation:</h2>
                        <p className='text-lg mt-3'>Users can cancel their subscription at any time, but no refunds will be provided for the remaining subscription period.</p>
                        <h2 className='text-2xl font-semibold mt-5 text-[#000F37]'>5. Contact Us:</h2>
                        <p className='text-lg mt-3'> If you have any questions or concerns about our refund policy, please contact us at <span className=' font-semibold t mt-5 text-[#000F37] cursor-pointer'onClick={handleEmailClick}>feedback@apnabillbook.com.</span></p>
                        <p className='text-lg mt-3'>By using our app and making a subscription purchase, you agree to abide by this refund policy.</p>
                        <p className='text-lg mt-3 mb-7'>Thank you for choosing Apna Softwares. We appreciate your understanding and support.</p>
                        <p className='text-lg mt-3 mb-7'><span className=' font-semibold t mt-5 text-[#000F37] cursor-pointer'>Sincerely,</span><br/>Apna Softwares<br/>feedback@apnabillbook.com.</p>
                    </div>
                </div>
            </div>
            <Getstarted/>
        </>
    )
}

export default RefundPolicy
