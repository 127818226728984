import React from 'react';

const InputField = ({ id, label, type, placeholder, valueData, onChange, errorText, helperText }) => {
    return (
        <div className='flex flex-col'>
            <label htmlFor={label} className="text-base font-semibold leading-none text-gray-800">{label}</label>
            <input
                tabIndex={0}
                id={id}
                aria-label={placeholder}
                type={type}
                className="text-base leading-none text-gray-900 p-3 focus:outline-none focus:border-[#0E7cF2] mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                placeholder={placeholder}
                value={valueData}
                onChange={(event) => onChange(event.target.value)}
            />
            {errorText && <p className="text-sm text-red-500">{errorText}</p>}
        </div>
    );
};

export default InputField;
