import React from 'react';

const AppleStoreButton = () => {
  const iOSUrl = "https://apps.apple.com/app/apna-billbook/id6480403753"; 

  return (
    <div className='md:flex lg:flex justify-center items-center space-x-4'>

      <a
        href={iOSUrl}
        target="_blank"
        rel="noopener noreferrer"
        className=""
      >
        <div class="flex mt-1  w-40 h-[64px] bg-black text-white rounded-lg items-center justify-center">
          <div class="mr-4 ">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305 305" width="30" height="30">
              <path
                fill="#FFF"
                d="M40.74,218.27c6.24,13.95,13.55,27.93,24.68,42.17c11.94,15.55,24.02,31.18,42.99,30.68
            c17.12-.47,22.93-11.07,43.09-11.07c20.09,0,25.02,10.98,43.07,10.78c18.55-.19,30.36-15.62,42.24-31.27
            c12.76-17.29,17.98-33.89,18.36-34.79c-.4-.18-32.67-12.47-32.89-49.43c-.22-30.95,25.04-45.65,26.16-46.32
            c-14.28-20.87-36.27-23.19-43.87-23.56c-18.54-1.47-36.17,10.92-45.55,10.92c-9.37,0-23.83-10.5-39.34-10.2
            c-20.22.29-38.85,11.92-49.22,30.4c-21.37,37.02-5.49,91.78,15.34,122.11ZM184.77,85.35c8.28-9.94,13.9-23.76,12.37-37.35
            c-11.99,.47-26.42,8.22-34.88,18.16c-7.6,8.72-14.22,22.74-12.44,36.09c13.15,.76,26.78-6.62,34.95-16.9Z"
              />
            </svg>
          </div>
          <div>
            <div className="text-xs">Download on the</div>
            <div className="text-base font-semibold font-sans -mt-1">App Store</div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default AppleStoreButton;