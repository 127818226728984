import React, { useState } from "react";
import QRCode from "react-qr-code";

function QRCodeComponent() {
    return (
        <div>
            <div className="">
                <h1 className="text-black lg:text-4xl leading-tight  text-3xl font-[600] mb-2 text-center text-[20px]">Scan the QR code</h1>
                <h2 className=" lg:text-[18px] text-[#575757] text-sm lg:text-start md:text-start text-center">to download the app and get started within seconds.</h2>
                <div className="flex justify-center mt-3 mb-1 lg:mt-7">
                    <QRCode value="https://play.google.com/store/apps/details?id=app.apnabillbook.com" renderAs="canvas" size={150} />,
                </div>

            </div>
        </div>
    );
}

export default QRCodeComponent;
