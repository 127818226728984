
import React, { useEffect, useState } from 'react'
import { BiLogoFacebook } from 'react-icons/bi'
import { FaWhatsapp } from 'react-icons/fa';


const SideButton = () => {
    const [showButton, setShowButton] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [playHovered, setPlayHovered] = useState(false);
    const [windowHovered, setWindowHovered] = useState(false);
    const [appHovered, setAppHovered] = useState(false);
    const [facebookHovered, setFacebookHovered] = useState(false);
    const [youTubeHovered, setYouTubeHovered] = useState(false);

    const youtubeLink = 'https://www.youtube.com/channel/UCQGyKnLpuKuLeeWg5UwTT6A';
    const facebookLink = 'https://www.facebook.com/apnabillbook';
    const iOSUrl = "https://apps.apple.com/app/apna-billbook/id6480403753";
    const instagramLink = 'https://www.instagram.com/apnabillbook/';
    const linkedinLink = 'https://www.linkedin.com/company/apna-softwares/about/';
    const whatsappLink = () => {
        const phoneNumber = '+919258712434';
        const message = 'Welcome to Apna Billbook!';
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');
    };

    const handleWindowAppDownload = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/apnabillbook.exe`;
        link.setAttribute('download', 'apnabillbook.exe');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const playstoreLink = 'https://play.google.com/store/apps/details?id=app.apnabillbook.com';

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 350) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className='hidden lg:block md:block xl:block'>
                <div
                    className={`contact-button-playstore  ${playHovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setPlayHovered(true)}
                    onMouseLeave={() => setPlayHovered(false)}
                >
                    {showButton && (
                        <div>

                            <a
                                href={playstoreLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=""
                            >
                                <div class="flex mt-3  w-48 h-14 bg-black text-white rounded-l-full items-center justify-center">
                                    <div class="mr-4 ">
                                        <svg viewBox="30 336.7 120.9 129.2" width="30">
                                            <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
                                            <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
                                            <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
                                            <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <div class="text-xs">GET IT ON</div>
                                        <div class="text-base font-semibold font-sans -mt-1">Google Play</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                </div>
                <div
                    className={`contact-button-appstore  ${appHovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setAppHovered(true)}
                    onMouseLeave={() => setAppHovered(false)}
                >
                    {showButton && (
                        <div>
                            <a
                                href={iOSUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=""
                            >
                                <div class="flex mt-3  w-48 h-14 bg-black text-white rounded-l-full items-center justify-center">
                                    <div class="mr-4 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305 305" width="30" height="30">
                                            <path
                                                fill="#FFF"
                                                d="M40.74,218.27c6.24,13.95,13.55,27.93,24.68,42.17c11.94,15.55,24.02,31.18,42.99,30.68
            c17.12-.47,22.93-11.07,43.09-11.07c20.09,0,25.02,10.98,43.07,10.78c18.55-.19,30.36-15.62,42.24-31.27
            c12.76-17.29,17.98-33.89,18.36-34.79c-.4-.18-32.67-12.47-32.89-49.43c-.22-30.95,25.04-45.65,26.16-46.32
            c-14.28-20.87-36.27-23.19-43.87-23.56c-18.54-1.47-36.17,10.92-45.55,10.92c-9.37,0-23.83-10.5-39.34-10.2
            c-20.22.29-38.85,11.92-49.22,30.4c-21.37,37.02-5.49,91.78,15.34,122.11ZM184.77,85.35c8.28-9.94,13.9-23.76,12.37-37.35
            c-11.99,.47-26.42,8.22-34.88,18.16c-7.6,8.72-14.22,22.74-12.44,36.09c13.15,.76,26.78-6.62,34.95-16.9Z"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <div class="text-xs">Download on the</div>
                                        <div class="text-base font-semibold font-sans -mt-1">App Store</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                </div>
                <div
                    className={`contact-button-window cursor-pointer  ${windowHovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setWindowHovered(true)}
                    onMouseLeave={() => setWindowHovered(false)}
                >
                    {showButton && (
                        <div>

                            <div
                                onClick={handleWindowAppDownload}
                            >
                                <div class="flex mt-3  w-48 h-14 bg-black text-white rounded-l-full items-center justify-center">
                                    <div className="mr-8">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30" height="30">
                                            <path fill="#FFF" d="M6 6h17v15H6zm19 0l17-2v17H25zM6 25h17v17H6zm19 0l17 2v15H25z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <div className="text-xs">Download for</div>
                                        <div className="text-base font-semibold font-sans -mt-1">Windows</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={`contact-button-group  ${hovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    {showButton && (
                        <div>
                            <div
                                onClick={whatsappLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="contact-button rounded-l-full py-2 px-5 "
                            >
                                <FaWhatsapp className="whatsapp-icon text-white" />

                                <button className="contact-button-text text-2xl ">Contact Us</button>

                            </div>

                        </div>
                    )}
                </div>
                <div
                    className={`contact-button-facebook  ${facebookHovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setFacebookHovered(true)}
                    onMouseLeave={() => setFacebookHovered(false)}
                >
                    {showButton && (
                        <div>
                            <a
                                href={facebookLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center bg-[#0e7cf2] rounded-l-full py-2  px-5 text-white "
                            >
                                <BiLogoFacebook className=" facebook-icon" />

                                <button className=" text-2xl  pl-2 mr-[1px]"> Facebook</button>

                            </a>

                        </div>
                    )}
                </div>
                <div
                    className={`contact-button-YouTube ${youTubeHovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setYouTubeHovered(true)}
                    onMouseLeave={() => setYouTubeHovered(false)}
                >
                    {showButton && (
                        <div>

                            <a
                                href={youtubeLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=""
                            >
                                <div class="flex w-[190px] h-14 rounded-l-full text-white    bg-red-600  items-center justify-center space-x-3">

                                    <div class="">
                                        <svg class="w-10 ml-2 fill-current " viewBox="0 0 24 24" >
                                            <path
                                                d="M21.9 5.9c-.2-.7-.7-1.2-1.4-1.4C18.3 4 12 4 12 4s-6.3 0-8.5.5c-.7.2-1.2.7-1.4 1.4C2 8.1 2 12 2 12s0 3.9.5 5.1c.2.7.7 1.2 1.4 1.4 2.2.5 8.5.5 8.5.5s6.3 0 8.5-.5c.7-.2 1.2-.7 1.4-1.4.5-1.2.5-5.1.5-5.1s0-3.9-.5-5.1zM9.5 15.5V8.5l6.5 3z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <div class="text-2xl mr-4">YouTube</div>

                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                </div>

            </div>
            <div className='xl:hidden lg:hidden md:hidden'>
                <div
                    className={`top-[20%] fixed right-0   ${playHovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setPlayHovered(true)}
                    onMouseLeave={() => setPlayHovered(false)}
                >
                    {showButton && (
                        <div>

                            <a
                                href={playstoreLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=""
                            >
                                <div class="rounded-l-full py-2 pl-4 pr-1 bg-black">
                                    <div class="">
                                        <svg viewBox="30 336.7 120.9 129.2" width="30">
                                            <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
                                            <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
                                            <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
                                            <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
                                        </svg>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                </div>
                <div
                    className={`top-[30%] fixed right-0   ${appHovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setAppHovered(true)}
                    onMouseLeave={() => setAppHovered(false)}
                >
                    {showButton && (
                        <div>
                            <a
                                href={iOSUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=""
                            >
                                <div class="rounded-l-full py-2 pl-4 pr-1 bg-black">
                                    <div class="">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305 305" width="30" height="30">
                                            <path
                                                fill="#FFF"
                                                d="M40.74,218.27c6.24,13.95,13.55,27.93,24.68,42.17c11.94,15.55,24.02,31.18,42.99,30.68
            c17.12-.47,22.93-11.07,43.09-11.07c20.09,0,25.02,10.98,43.07,10.78c18.55-.19,30.36-15.62,42.24-31.27
            c12.76-17.29,17.98-33.89,18.36-34.79c-.4-.18-32.67-12.47-32.89-49.43c-.22-30.95,25.04-45.65,26.16-46.32
            c-14.28-20.87-36.27-23.19-43.87-23.56c-18.54-1.47-36.17,10.92-45.55,10.92c-9.37,0-23.83-10.5-39.34-10.2
            c-20.22.29-38.85,11.92-49.22,30.4c-21.37,37.02-5.49,91.78,15.34,122.11ZM184.77,85.35c8.28-9.94,13.9-23.76,12.37-37.35
            c-11.99,.47-26.42,8.22-34.88,18.16c-7.6,8.72-14.22,22.74-12.44,36.09c13.15,.76,26.78-6.62,34.95-16.9Z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )}
                </div>
                <div
                    className={`top-[40%] fixed right-0    ${windowHovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setWindowHovered(true)}
                    onMouseLeave={() => setWindowHovered(false)}
                >
                    {showButton && (
                        <div>

                            <div
                                onClick={handleWindowAppDownload}
                            >
                                <div class="rounded-l-full py-2 pl-4 pr-1 bg-black">
                                    <div class="">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30" height="30">
                                            <path fill="#FFF" d="M6 6h17v15H6zm19 0l17-2v17H25zM6 25h17v17H6zm19 0l17 2v15H25z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={`top-[50%] fixed right-0   ${hovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    {showButton && (
                        <div>
                            <div
                                onClick={whatsappLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=" "
                            >
                                <FaWhatsapp className="bg-[#49C95A] text-white text-[50px] rounded-l-full py-2 pl-2 " />



                            </div>

                        </div>
                    )}
                </div>
                <div
                    className={`top-[60%] fixed right-0   ${hovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    {showButton && (
                        <div>
                            <a
                                href={facebookLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=" "
                            >
                                <BiLogoFacebook className="bg-[#0e7cf2] text-white text-[50px] rounded-l-full py-1 pl-2 " />

                            </a>

                        </div>
                    )}
                </div>
                <div
                    className={`top-[70%] fixed right-0   ${playHovered ? 'hovered' : ''}`}
                    onMouseEnter={() => setPlayHovered(true)}
                    onMouseLeave={() => setPlayHovered(false)}
                >
                    {showButton && (
                        <div>

                            <a
                                href={youtubeLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=""
                            >
                                <div class="rounded-l-full text-white  py-1 pl-2 pr-1 bg-red-600">
                                    <div class=" ">
                                        <svg class="w-9 h-10 fill-current " viewBox="0 0 24 24">
                                            <path
                                                d="M21.9 5.9c-.2-.7-.7-1.2-1.4-1.4C18.3 4 12 4 12 4s-6.3 0-8.5.5c-.7.2-1.2.7-1.4 1.4C2 8.1 2 12 2 12s0 3.9.5 5.1c.2.7.7 1.2 1.4 1.4 2.2.5 8.5.5 8.5.5s6.3 0 8.5-.5c.7-.2 1.2-.7 1.4-1.4.5-1.2.5-5.1.5-5.1s0-3.9-.5-5.1zM9.5 15.5V8.5l6.5 3z" />
                                        </svg>
                                    </div>

                                </div>
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SideButton