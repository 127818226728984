import React from 'react'
import Home from './Component/Section/Home'
import DownloadButtom from './Component/Section/DownloadButtom'
import Features from './Component/Section/Features'
import Testmonial from './Component/Section/Testmonial'
import PageTop from './Component/scrollTop/PageTop'
import ContactUsFooter from './Component/Footer/ContactUsFooter'
import Faq from './Component/Faq/Faq'

const Homepage = () => {
  return (
    <>
      <PageTop />
      <div className="">
        <Home />
      </div>
      <DownloadButtom />
      <Features />
      <Faq title="Frequently Asked Question's " fromHome={true} />
      <Testmonial />
      <ContactUsFooter />
    </>
  )
}

export default Homepage