import React, { useState } from 'react'
import apiService from '../Baseurl/apiService';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import ContentDialog from '../DialogBox/ContentDialog';

function CustomizedInputBase({ setError, small }) {
    const [phoneNumber, setPhoneNumber] = useState("");
    // const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [contentDialogOpen, setContentDialogOpen] = useState(false)
    const handlePhoneNumberChange = (event) => {
        const numericValue = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
        if (/^[0123456789]\d{0,9}$/.test(numericValue) || numericValue === "") {
            setPhoneNumber(numericValue);
            if (numericValue.length === 10) {
                setError(false);
            } else if (numericValue.length == 0) {
                setError(false);
            } else {
                setError(true);
            }
        }
        else {
            console.log("Phone number is invalid. Please enter a valid phone number.");
            // You can add error handling logic here
            setError(true);
        }
    };
    const handleSendClick = () => {
        if (phoneNumber) {
            const endpoint = "/notification/sendDownloadAppMessage";

            apiService
                .post(endpoint, { mobileNumber: phoneNumber })
                .then((response) => {
                    if (response.status === 200) {
                        console.log("Phone number sent successfully!");
                        setContentDialogOpen(true); // Ensure this line is setting isDialogOpen to true
                    } else {
                        console.error("Failed to send phone number.");
                    }
                })
                .catch((error) => {
                    console.error("Error sending phone number:", error);
                });
        } else {
            alert("Phone number is empty. Please enter a valid phone number.");
        }
    };
    const closeVideoDialog = () => {
        setContentDialogOpen(false);
    };
    return (
        <div className="justify-center" >
            <div
                component="form"

                style={{
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    borderWidth: '2px',
                    borderColor: '#0e7ef4',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                    p: '2px 4px',
                    display: 'flex',
                    height: small ? '50px' : null,
                    alignItems: 'center',

                }}
            >
                <IconButton sx={{
                    p: small ? '10px' :
                        '10px'
                }} aria-label="menu">
                    <div className={small ? 'text-[12px] font-normal' :
                        'lg:text-[17px] text-2xl font-normal'}>
                        +91
                    </div>
                </IconButton>
                <InputBase
                    sx={{
                        ml: 1,
                        flex: 1,
                        fontSize: small ? '14px' : '17px', // Font size for the input text
                        fontWeight: 'normal',
                        fontFamily: 'inherit',
                    }}
                    type="tel"
                    value={phoneNumber}
                    placeholder={small ? 'Enter Mobile number' : 'Enter 10 digit WhatsApp number'}
                    onChange={handlePhoneNumberChange}
                    inputProps={{
                        'aria-label': 'Enter mobile number',
                        'inputMode': 'numeric',
                    }}
                />



                <IconButton IconButton type="button"
                    sx={{ p: small ? '10px' : '10px' }} >
                    <button
                        onClick={handleSendClick}
                        disabled={!phoneNumber}
                        className={small ? 'h-7 rounded buttonbg absolute text-sm right-3 px-3 text-white hover:scale-105 ' :
                            'h-10 rounded buttonbg absolute text-lg right-2 px-6 text-white hover:scale-105 '}>
                        SEND
                    </button>
                </IconButton>
            </div>
            <ContentDialog
                isOpen={contentDialogOpen} onClose={closeVideoDialog}
                title={" We have send you a download link on your WhatsApp or you can scan the QR code"}
            />
        </div>
    );
}
export default CustomizedInputBase