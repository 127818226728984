import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import FixedTags from './AutoCompleteFlied';
import DraftEditor from './Draft';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../Baseurl/api';
import { IMAGE_URL } from '../Baseurl/api';
import Getstarted from '../Section/Getstarted';



const CreateBlog = () => {
    const [file, setFile] = useState(null);
    const [tags, setTags] = useState(null);
    const [content, setContent] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { register, handleSubmit, getValues, formState: { errors } } = useForm();
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };
    const handleTagsChange = (selectedTags) => {
        setTags(selectedTags.join(',')); // Convert the array of tags to a comma-separated string
    };
    const handleContentChange = (e) => {

        setContent(e);
        console.log("=====================123456", e)
    };


    const onSubmit = (data) => {
        // Create a new FormData object
        const formDataToSend = new FormData();

        // Append the file to the FormData object
        formDataToSend.append('register', file);

        // Append other form data fields to the FormData object
        formDataToSend.append('tags', tags);
        formDataToSend.append('content', content);
        // Call the API to upload the file
        const apiUrl = `${BASE_URL}/uploadFiles`;
        fetch(apiUrl, {
            method: 'POST',
            body: formDataToSend,
            headers: { Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aW1lIjoiU3VuIEF1ZyAyNyAyMDIzIDE1OjA3OjA2IEdNVCswNTMwIChJbmRpYSBTdGFuZGFyZCBUaW1lKSIsInVzZXJEZXRhaWxzIjp7ImlkIjo1MSwibmFtZSI6IlRhcnVuIiwiZW1haWwiOm51bGwsIm1vYmlsZSI6Ijc0MTczOTEyMjgiLCJzdG9yZUlkIjoiMGQxMGM3YTItZjFkYi00ZjUwLTgzYTAtYmQ2MTMwNWE4MWM2IiwiZGV2aWNlVG9rZW4iOm51bGwsImR1ZUFtb3VudCI6MCwidHlwZSI6bnVsbCwiYWRkcmVzc0xpbmUxIjoiVGVzdCBBZGRyZXNzIiwiYWRkcmVzc0xpbmUyIjoiIiwibGF0aXR1ZGUiOm51bGwsImxvbmdpdHVkZSI6bnVsbCwiY3JlYXRlZEF0IjoiMjAyMy0wNi0wMSAwMjowMDoxMiIsInVwZGF0ZWRBdCI6IjIwMjMtMDgtMjdUMDk6Mzc6MDYuMDY5WiIsImRlbGV0ZWRBdCI6bnVsbH0sImlhdCI6MTY5MzEyOTAyNn0.RTMD-fcYwVevjWUL3o4D_Th8K4dQiN1l1bRV2IM0AuU' }
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log('File upload response:', data);
                const postData = {
                    title: data.title,
                    shortContent: data.shortContent,
                    mediaType: data.mediaType,
                    mediaLink: IMAGE_URL + responseData.data[0].path,
                    tags: tags,
                    content: content
                    // Add other fields here
                };
                const createBlogUrl = `${BASE_URL}/blogs`;
                fetch(createBlogUrl, {
                    method: 'POST',
                    body: JSON.stringify(postData),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => response.json())
                    .then((status = true) => {
                        setIsDialogOpen(true);
                    })


            })
            .catch((error) => {
                console.error('File upload error:', error);
            });


        setIsDialogOpen(true);
    };



    return (
        <>
            <section className="max-w-4xl p-6 mx-auto bg-sky-50 rounded-md shadow-md mt-20 mb-12">
                <h1 className="text-xl font-bold text-black capitalize">Create A Blog</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className=" mt-4">
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <div>
                                <label className="block text-black" htmlFor="title">
                                    Title
                                </label>
                                <input
                                    id="title"
                                    type="text"
                                    {...register('title', { required: true })}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md"
                                />
                                {errors.title && <span className="text-red-500">This field is required</span>}
                            </div>

                            <div>
                                <label className="block text-black" htmlFor="shortContent">
                                    Short Content
                                </label>
                                <textarea
                                    id="shortContent"
                                    {...register('shortContent', { required: true })}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md"
                                ></textarea>
                                {errors.shortContent && <span className="text-red-500">This field is required</span>}
                            </div>
                        </div>
                        <div>
                            <label className="block w-full text-black" htmlFor="content">
                                Content
                            </label>
                            <DraftEditor handleContentChange={handleContentChange} />
                            {!content && <span className="text-red-500">This field is required</span>}
                        </div>
                        <div className="grid grid-cols-1 mt-4 gap-6 sm:grid-cols-2">
                            <div>
                                <label className="block text-sm font-medium text-black">Media Link</label>
                                <div className="mt-1 pt-5 pb-6 px-4 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1">
                                        <div className="mb-4">
                                            <input
                                                type="file"
                                                {...register('mediaLink', { required: true })}
                                                accept="image/*,video/*"
                                                onChange={handleFileChange}
                                                className="mb-2"
                                            />

                                        </div>
                                        {errors.mediaLink && <span className="text-red-500">This field is required</span>}
                                    </div>

                                </div>
                            </div>
                            <div>
                                <label className="block text-black" htmlFor="mediaType">
                                    Media Type
                                </label>
                                <input
                                    id="mediaType"
                                    type="text"
                                    {...register('mediaType', { required: true })}
                                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md"
                                />
                                {errors.mediaType && <span className="text-red-500">This field is required</span>}
                            </div>
                        </div>
                    </div>
                    <div className="my-14">
                        <FixedTags handleTagsChange={handleTagsChange} />
                        {!tags && <span className="text-red-500">This field is required</span>}
                    </div>
                    <div className="flex justify-end mt-6">
                        <button
                            type="submit"
                            className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-[#0E7CF2] rounded-md hover:bg-[#468fde]"
                        >
                            Upload Blog
                        </button>
                    </div>
                </form>
                {isDialogOpen && (
                    <div className="dialog">
                        <div className="dialog-content">
                            <div
                                class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
                            >
                                <svg
                                    class="h-6 w-6 text-green-600"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M5 13l4 4L19 7"
                                    ></path>
                                </svg>
                            </div>
                            <h3 class="text-lg leading-6 font-medium text-gray-900">Successful!</h3>
                            <div class="mt-2 px-7 py-3">
                                <p class="text-sm text-gray-500 text-center">
                                    Blog Created Successfully
                                </p>
                            </div>

                            <button type="button" onClick={() => {
                                setIsDialogOpen(false);
                                window.location.reload();
                            }}>Close</button>

                        </div>
                    </div>
                )}

            </section>
            <Getstarted/>
        </>
    );
};

export default CreateBlog;
