import React, { useEffect, useState } from 'react'
import whatsapp from "../../Assest/logoimage/whatsapp.svg"


import CustomizedInputBase from './ApnaWhatsappField';

const GetlinkHome = () => {
    const [haveError, setError] = useState(false);
    const [mobile, setMobile] = useState('');

    // on load
    window.addEventListener('resize', () => {
        setMobile(window.innerWidth <= 768);
    });


    useEffect(() => {
        // get screen is mobile or not
        const isMobile = () => {
            const ua = navigator.userAgent;
            return /Android|Mobi/i.test(ua);
        };
        if (isMobile()) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [mobile])
    return (
        <>
            <div className="relative justify-center px-1 items-center 
            md:px-20 sm:px-20 lg:px-0 m-0">
                <div className="flex">
                    <div className="xl:mb-8 sm:mb-5 md:mb-5 mb-5
                text-[black] lg:text-2xl text-[12px] flex justify-center  items-center
                font-bold text-center flex-auto">
                        Get the Apna Billbook link to your WhatsApp <span>
                            {mobile ? <img src={whatsapp} alt=""
                                className='ml-1 w-5 h-5'

                            /> : null}
                        </span>
                    </div>
                    {mobile ? null : <img src={whatsapp} alt=""
                        className='ml-2 w-8 h-8'

                    />}
                </div>

                <CustomizedInputBase
                    setError={setError}
                    small={mobile}
                />
                <div className='mt-5 text-[10px] justify-center flex text-center'>
                    By continuing, I agree to receive WhatsApp communication from Apna Billbook.
                </div>
                {
                    haveError ?
                        <div className='text-red-500 text-sm justify-center flex mt-5'>
                            Please enter a valid mobile number
                        </div>
                        : null
                }
                {/* <input
                    type="tel"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    className="h-14 w-full pr-20 bg-white pl-4 xl:pr-20 
                    rounded-lg border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-[#0e7ef2] focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none"
                    placeholder="Enter mobile number"
                    name=""
                /> */}
                {/* <button
                    onClick={handleSendClick}
                    disabled={!phoneNumber}
                    className="h-10  rounded buttonbg absolute top-2 text-lg right-2 px-2 text-white hover:scale-105"
                >
                    SEND
                </button> */}


            </div>
        </>
    )
}
export default GetlinkHome