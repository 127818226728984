import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import apiService from '../Baseurl/apiService';
import PageTitle from '../Section/PageTitel/Pagetitle';
import { BsSearch } from 'react-icons/bs';
import Blogtag from './Blogtag';
import ApnaPagination from './ApnaPagination';
import Getstarted from '../Section/Getstarted';


const BlogPost = () => {
  const [fix, setFix] = useState(false);
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const [totalPage, setTotalPage] = useState()

  const navigate = useNavigate();


  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', Navstick);
    return () => {
      window.removeEventListener('scroll', Navstick);
    };
  }, []);
  async function fetchData() {
    setLoading(true);

    const endpoint = `/blogs?page=${currentPage}&pageSize=${postsPerPage}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    try {
      const response = await apiService.get(endpoint, requestOptions);
      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = response.data;
      setTotalPage(responseData.data.totalPages)


      if (Array.isArray(responseData.data.data)) {
        setBlog(responseData.data.data);
        const firstBlog = responseData.data.data[0]; // Default to the first blog for meta data
        addMetaData(firstBlog.title, firstBlog.shortContent, firstBlog.mediaLink);
      }
      else {
        throw Error('Data received is not an array');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, [currentPage, postsPerPage]);
  const paginate = ({ selected }) => {
    setCurrentPage(selected);
    fetchData()
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    const indexOfLastPost = page * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = blog.slice(indexOfFirstPost, indexOfLastPost);
    // You can remove the 'paginate' function from here
  };

  function addMetaData(title, shortContent, mediaLink) {
    const ogImage = mediaLink;
    const ogURL = "https://apnabillbook.com/blog";
    const twitterImage = "./getstarted.jpg";
    // Set the title
    const title65Char = title.substring(0, 65) ?? title;
    const description155Char = shortContent.substring(0, 155) ?? shortContent;
    document.title = title65Char;
    // Set the meta tags
    document.querySelector('meta[name="title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="description"]').setAttribute("content", description155Char);
    document.querySelector('meta[property="og:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[property="og:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[property="og:image"]').setAttribute("content", ogImage);
    document.querySelector('meta[property="og:url"]').setAttribute("content", ogURL);
    document.querySelector('meta[name="twitter:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="twitter:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[name="twitter:image"]').setAttribute("content", twitterImage);

  }


  return (
    <>
      {(loading) ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : ((blog) ? (
        <>
          <div className={fix ? 'w-full  bg-white' : ' bg-white'}>
            <div
              className={`lg:px-16  border border-1 py-3 ${fix ? 'fixed border border-1 bg-white w-full xl:mt-16 lg:mt-16 mt-16 z-50' : ''
                }`}
            >
              <PageTitle activeMenu="Blog" />
            </div>
          </div>
          <div className="bg-sky-50 mb-14">
            <h1 className="text-center mb-10 text-[#313131] lg:text-[50px] xl:text-[50px] text-[20px] pt-10
        font-bold">
              Knowledge Corner
            </h1>
            <div className="lg:grid grid-cols-2 gap-28 xl:px-20 px-8 container mx-auto">
              <div className="mb-6 lg:w-[140%] ">
                <section className="text-gray-700">
                  <div>
                    <div className="xl:grid grid-cols-2 gap-10">
                      {blog.map((e, index) => (
                        <div className="">
                          <Link
                            key={index}
                            state={e}
                            to={`/blog-post/${e.title.replace(/ /g, '-')}`}
                          >
                            <div className="max-w-xl h-full bg-white border border-gray-200 rounded-lg shadow lg:mb-10 mb-5">

                              <img className="rounded-t-lg h-72 w-full"
                                src={e.mediaLink}
                                alt={e.mediaType}

                              />
                              <div className="px-5 ">
                                <h5 className="mb-2 text-xl font-semibold tracking-tight line-clamp-2 mt-5">{e.title}</h5>
                                <p className="mb-3 font-normal text-gray-700 line-clamp-3">{e.shortContent}</p>
                                <p className="mb-3 font-normal text-gray-700">{e.createdAt}</p>

                                <button className="lg:w-full  hover:bg-[#0E7cF2]  hover:text-white items-center px-3 py-2 text-sm font-medium flex justify-center text-[#0E7CF2] border border-[#0E7cF2] rounded-lg mb-4">
                                  Read More
                                  <svg className="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M1 5h12m0 0L9 1m4 4L9 9"
                                    />
                                  </svg>
                                </button>

                              </div>
                            </div>
                          </Link>
                          {/* Display page numbers as buttons */}

                        </div>
                      ))}

                    </div>
                  </div>
                  <div className="flex justify-center my-8">
                    <ApnaPagination currentPage={currentPage} totalPage={totalPage} onPageChange={handlePageClick} />
                  </div>
                </section>
              </div>
              <div className="xl:w-[62%] xl:ml-56 mt-8">
                <div className="mb-3">
                  <div className="relative flex justify-center">
                    <input
                      type="text"
                      className="w-full pr-20 bg-white pl-4 xl:pr-20 rounded-lg border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-3 text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:border-[#0e7ef2]"
                      placeholder="Search..."
                      name=""
                    />
                    <button className="w-14 rounded buttonbg absolute top-2 text-lg right-2 px-1 py-2 text-white hover:scale-105 flex justify-center">
                      <BsSearch className="" />
                    </button>
                  </div>
                </div>
                <div className="sticky mb-10 mt-10 xl:w-[100%] lg:w-[45%]">
                  <Blogtag />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Getstarted />
          </div>
        </>
      ) : (
        // 404 page
        <body>
          <div className="mb-14">
            <div className="bg-sky-50 py-8">
              <h1 className="text-center text-[#313131] lg:text-[50px] text-5xl font-bold">
                404 Page Not Found
              </h1>
              <div className='flex justify-center items-center space-x-4 mt-4 '>
                OOPS! THE PAGE YOU WERE LOOKING FOR, COULD NOT BE FOUND.
              </div>
            </div>
          </div>
        </body>
      ))}
    </>
  );
};

export default BlogPost;
