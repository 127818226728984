import React, { useState, useEffect } from "react";
import { TiTick } from "react-icons/ti";
import PageTitle from "../Section/PageTitel/Pagetitle";
import Getstarted from "../Section/Getstarted";

function Pricing() {

    const [fix, setFix] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };


    const Navstick = () => {
        if (window.scrollY >= 30) {
            setFix(true);
        } else {
            setFix(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", Navstick);
        return () => {
            window.removeEventListener("scroll", Navstick);
        };
    }, []);

    useEffect(() => {
        const scriptId = 'razorpay-embed-btn-js';
        
        // Remove existing script if it exists to ensure reloading
        const existingScript = document.getElementById(scriptId);
        if (existingScript) {
          existingScript.remove();
        }
    
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://cdn.razorpay.com/static/embed_btn/bundle.js';
        script.defer = true;
        document.body.appendChild(script);
    
        // Initialize Razorpay button when the script loads
        script.onload = () => {
          if (window._rzp_ && window._rzp_.init) {
            window._rzp_.init();
          }
        };
      }, []);


    function addMetaData() {
        const title = "Apna Billbook - Best Shop & Bill Management App for Small Retailers and Restaurants";
        const description = "Say goodbye to manual billing and hello to automated invoicing, online shop, inventory management, and expense tracking.";
        const ogImage = "https://app.apnabillbook.com/getstarted.jpg";
        const ogURL = "https://apnabillbook.com/pricing";
        const twitterImage = "./getstarted.jpg";
        // Set the title
        document.title = title;

        const title65Char = title.substring(0, 65) ?? title;
        const description155Char = description.substring(0, 155) ?? description;

        // Set the meta tags
        document.querySelector('meta[name="title"]').setAttribute("content", title65Char);
        
        document.querySelector('meta[name="description"]').setAttribute("content", description155Char);
        document.querySelector('meta[property="og:title"]').setAttribute("content", title65Char);
        document.querySelector('meta[property="og:description"]').setAttribute("content", description155Char);
        document.querySelector('meta[property="og:image"]').setAttribute("content", ogImage);
        document.querySelector('meta[property="og:url"]').setAttribute("content", ogURL);
        document.querySelector('meta[name="twitter:title"]').setAttribute("content", title65Char);
        document.querySelector('meta[name="twitter:description"]').setAttribute("content", description155Char);
        document.querySelector('meta[name="twitter:image"]').setAttribute("content", twitterImage);

    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        if(window.location.href === '/pricing'){
        addMetaData()
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div
                className={fix ? "w-full  bg-white" : " bg-white"}
            >
                <div className={`lg:px-16 border border-top-1 py-3 ${fix ? "fixed border border-top-1 shadow-sm bg-white w-full mt-16 z-50 " : " "
                    }`}>
                    <PageTitle
                        activeMenu="Pricing"
                    />
                </div>
            </div>

            <div className="bg-sky-50 py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-4xl font-semibold text-gray-900 text-center mb-2 font-serif">Make the wise decision <br /> for your business</h2>
                    <h2 className="text-lg  text-gray-500 text-center mb-8 font-serif">Choose from our affordable 3 packages</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {pricingPlans.map((plan) => (
                            <div
                                key={plan.name}
                                className="bg-white pb-24 px-0 relative rounded-lg shadow-lg overflow-hidden"
                            >
                                <div className="px-6 pt-8 pb-4 text-[#0E7CF2]">
                                    <h3 className="text-2xl font-bold">{plan.name}</h3>
                                    <p className="text-lg text-red-500 font-semibold mt-2">{plan.discount}</p>
                                    <div className="flex items-end space-x-2">
                                        <p className="text-2xl font-semibold white mt-2">{plan.price}</p>
                                        <span className="text-xl font-bold ">/-</span>
                                        <span className="text-xl font-bold text-gray-400  line-through">{plan.mainPrice}</span>
                                    </div>
                                </div>
                                <hr class={` h-0.5 bg-[#0e7cf2] `}></hr>
                                <div className="px-6 py-8">
                                    <p className="text-gray-600">{plan.description}</p>
                                    <ul className=" pl-6 mt-6 space-y-2">
                                        {plan.features.map((feature) => (
                                            <div className="flex items-center space-x-1">
                                                < TiTick className="bg-[#0E7CF2] rounded-full text-white " />
                                                <li key={feature} className="text-gray-800">{feature}</li>
                                            </div>
                                        ))}
                                    </ul>
                                    <div className="absolute bottom-0 text-center left-0 right-0 ">
                                        <div className="inline-flex">
                                            <div
                                                className="razorpay-embed-btn w-full text-white font-bold  "
                                                data-url="https://pages.razorpay.com/pl_P9fl0OXPkGZp1r/view"
                                                data-text="Pay Now"
                                                data-color="#0E7CF2"
                                                data-size="large"
                                              
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Getstarted />


        </>
    );
}

export default Pricing;


const pricingPlans = [
    {
        name: '1 Year Plan',
        discount: '50% off',
        price: '3499',
        mainPrice: '6998/-',
        description: 'Best for small business owners, startups who needs landing page for their business.',
        features: ['Full features unlocked', 'Active customer support', '58mm portable printer free'],
    },
    {
        name: '2 Year Plan',
        discount: '50% off',
        price: '4999',
        mainPrice: '8998/-',
        description: 'Best for medium business owners, startups who needs landing page for their business.',
        features: ['Full features unlocked', 'Active customer support', '58mm portable printer free'],
    },
    {
        name: '3 Year Plan',
        discount: '50% off',
        price: '6499',
        mainPrice: '12998/-',
        description: 'Best for large companies, business owners who needs landing page for their business.',
        features: ['Full features unlocked', 'Active customer support', '58mm portable printer free'],
    },
];
