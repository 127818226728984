import React, { useEffect, useState } from 'react'
import PageTitle from '../Section/PageTitel/Pagetitle'
import Googleplay from '../Buttom/Googleplay';
import Window from '../Buttom/Window';
import { BsDot } from "react-icons/bs";
import ProductCategory from '../ProductCategory/ProductCategory';
import Pricing from '../Pricing/Pricing';
import ContactUsFooter from '../Footer/ContactUsFooter';
import AppleStoreButton from '../Buttom/Applestore';

const CustomerUdhaar = () => {
  const [fix, setFix] = useState(false);

  function addMetaData() {
    const title = "Cloud base Customer Udhaar";
    const description = "Manage customer credit effectively with Apna Billbook’s Udhar Management feature. Record credit sales, monitor outstanding balances, and generate detailed reports to keep your cash flow healthy and minimize risks.";
    const ogImage = "https://app.apnabillbook.com/uploads/1729980230792Customer Udhaar.jpg";
    const ogURL = "https://apnabillbook.com/customer-udhaar";
    const twitterImage = "./twitter.jpg";
    const keywords = "Debt management software, udhar management tool, billing software with credit management​(Apna Billbook features)​(Apna Billbook features), customer udhaar";
    document.title = title;

    const title65Char = title.substring(0, 65) ?? title;
    const description155Char = description.substring(0, 155) ?? description;

    // Set the meta tags
    document.querySelector('meta[name="title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="description"]').setAttribute("content", description155Char);
    document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
    document.querySelector('meta[property="og:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[property="og:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[property="og:image"]').setAttribute("content", ogImage);
    document.querySelector('meta[property="og:url"]').setAttribute("content", ogURL);
    document.querySelector('meta[name="twitter:title"]').setAttribute("content", title65Char);
    document.querySelector('meta[name="twitter:description"]').setAttribute("content", description155Char);
    document.querySelector('meta[name="twitter:image"]').setAttribute("content", twitterImage);

  }

  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    addMetaData()
    window.addEventListener("scroll", Navstick);
    return () => {
      window.removeEventListener("scroll", Navstick);
    };
  }, []);
  return (
    <>
      <div
        className={fix ? "w-full  bg-white" : " bg-white"}
      >
        <div className={`lg:px-16 border border-top-1 py-3 ${fix ? "fixed border border-top-1 shadow-sm bg-white w-full mt-16 z-50 " : " "
          }`}>
          <PageTitle
            activeMenu="Customer Udhaar"
          />
        </div>
      </div>
      <div className=" w-full " >
        <div className=" overflow-hidden relative  inset-0 ">
          <img src="https://app.apnabillbook.com/uploads/1729974072630Container.png" alt="Container" className="absolute  inset-0 w-full h-screen object-cover " />
          <div class="relative lg:px-20 md:px-14 px-6 py-6  lg:flex md:flex justify-between items-center space-x-4 lg:mt-10 mt-2 ">
            <div >
              <h3 className="text-white lg:text-5xl text-[23px] uppercase tracking-wider  xl:mb-2">
                <h1 class="lg:space-y-8"> Customer Udhaar, made Easy
                </h1>
              </h3>
              <div class="lg:mt-10  mt-3  text-white tracking-wider text-base  lg:text-2xl ">

                <h2>
                  <span>
                  Manage customer credit effectively with Apna Billbook’s Udhar Management feature. Record credit sales, monitor outstanding balances, and generate detailed reports to keep your cash flow healthy and minimize risks.</span>
                </h2>
                <div className="lg:my-10 md:my-5 my-3 lg:flex  lg:space-x-4 md:flex md:space-x-4 grid grid-cols-2 gap-3 mb-8 " >
                  <Googleplay />
                  <AppleStoreButton />
                  <Window />
                </div>
              </div>
            </div>
            <div className="">
              <div className=" flex justify-center  w-full z-10">
                <div className="flex justify-center">

                  <div className="mt-2 
                    items-center  mx-10 -translate-x-2 -rotate-0 rounded-3xl bg-white p-1">
                    <div className=" translate-x-0 rotate-0 overflow-hidden rounded-3xl shadow-lg transition-transform hover:scale-100 ">
                      <video autoPlay muted loop className="h-full w-full  object-cover rounded-3xl"
                        poster="https://app.apnabillbook.com/getstarted.jpg"
                      >
                        <source src="https://app.apnabillbook.com/getstarted.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="bg-sky-50 py-16 mx-auto container lg:px-20 md:px-16 px-5">
        <div className="lg:flex md:flex justify-between items-center lg:space-x-10 md:space-x-8">
          <div className=" lg:w-1/2 md:md-w-1/2">
            <img
              src="https://app.apnabillbook.com/uploads/1729980230792Customer Udhaar.jpg"
              alt="Inventory"
              className="rounded-lg shadow-md"
            />
          </div>
          <div className="lg:mt-0 md:mt-0 mt-3 lg:w-1/2 md:md-w-1/2">
            <h1 className="text-3xl font-bold text-[#0E7CF2] mb-6">
              Cloud base Udhar management
            </h1>
            <div className="">
              <div className="">
                <p className="text-gray-700">
                Manage customer credit effectively with Apna Billbook’s Udhar Management feature. Record credit sales, monitor outstanding balances, and generate detailed reports to keep your cash flow healthy and minimize risks.
                </p>
                <h2 className="lg:text-xl text-lg font-serif font-semibold  mt-8 mb-4">
                Key Benefits:
                </h2>
                <ul className="list-disc  ">
                  <li className="flex  items-center mb-2">
                    < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                    Improve cash flow with real-time tracking of customer debts.
                  </li>
                  <li className="flex  items-center mb-2">
                    < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                    Simplify record-keeping and automate credit transactions.
                  </li>
                  <li className="flex  items-center mb-2">
                    < BsDot className="mr-3 text-[#0E7CF2] rounded-full text-2xl " />
                    Strengthen customer relationships by providing clear and transparent credit records.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="my-5">
        <h2 className="text-xl font-semibold  mt-8 mb-4">
                  Benefits of Udhar Management Feature in Billing Application
                </h2>
                <ul className="list-disc ">
                  <li className="flex  items-center ">
                    < BsDot className=" text-[#0E7CF2] rounded-full text-2xl " />
                    < h1 className=" text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5" >Improved Cash Flow:</h1>
                  </li>
                  Efficiently track and manage outstanding debts, ensuring timely paymentsand improving cash flow.

                  <li className="flex  items-center mt-4">
                    < BsDot className=" text-[#0E7CF2] rounded-full text-2xl " />
                    < h1 className=" text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5" >Enhanced Customer Relationships:</h1>
                  </li>
                  Maintain strong relationships with customers by providingclear and transparent billing information, including outstanding balances.

                  <li className="flex items-center mt-4">
                    <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
                    <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                      Reduced Bad Debts:
                    </h1>
                  </li>
                  <p className="">
                    Implement effective collection strategies to minimize bad debts and protect your business's financial health.
                  </p>


                  <li className="flex items-center mt-4">
                    <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
                    <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                      Simplified Record Keeping:
                    </h1>
                  </li>
                  <p className="">
                    Accurately record and manage all credit transactions, streamlining your accounting processes.
                  </p>


                  <li className="flex items-center mt-4">
                    <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
                    <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                      Data-Driven Decision Making:
                    </h1>
                  </li>
                  <p className="">
                    Gain valuable insights into your customers' payment habits, allowing you to make informed decisions regarding credit policies and collection efforts.
                  </p>


                  <li className="flex items-center mt-4">
                    <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
                    <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                      Increased Efficiency:
                    </h1>
                  </li>
                  <p className="">
                    Automate the management of credit and collection processes, saving time and resources.
                  </p>


                  <li className="flex items-center mt-4">
                    <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
                    <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                      Improved Customer Service:
                    </h1>
                  </li>
                  <p className="">
                    Provide better customer service by addressing inquiries and concerns related to credit and billing efficiently.
                  </p>


                  <li className="flex items-center mt-4">
                    <BsDot className="text-[#0E7CF2] rounded-full text-2xl" />
                    <h1 className="text-[#0E7CF2] font-serif font-semibold text-2xl mb-1.5">
                      Scalability:
                    </h1>
                  </li>
                  <p className="">
                    Easily accommodate growth in your business by scaling your udhar management capabilities.
                  </p>


                </ul>
        </div>
      </div>
      <div className="">
        <ProductCategory />
      </div>
      <div className="">
        <Pricing />
      </div>

      <ContactUsFooter />
    </>
  )
}

export default CustomerUdhaar;