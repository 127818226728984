import React, { useState } from 'react'
import logo from '../../Assest/logoimage/logo_blue.png'
import { SlSocialLinkedin } from 'react-icons/sl'
import { BsInstagram, BsYoutube } from 'react-icons/bs'
import { FiFacebook, FiTwitter } from 'react-icons/fi'
import { GrFacebookOption, GrLinkedin, GrYoutube } from "react-icons/gr";
import { TbBrandTwitter } from "react-icons/tb";
import { IoLogoInstagram } from "react-icons/io";
import { BsLinkedin } from 'react-icons/bs'
import { Link, NavLink } from 'react-router-dom'
import Getlink from '../AppgetLinkdownload/ApnaWhatsappField'
import { SiLinkedin } from 'react-icons/si'

const Footer = () => {

    return (
        <>
            <div className="bg-sky-50">
                <footer
                    class=" xl:px-14 px-6  container mx-auto lg:text-left">
                    <div class="py-10  md:text-left">
                        <div class="grid-1 grid md:grid-cols-3 gap-4   xl:grid-cols-4">
                            <div class="hidden md:block">
                                <div
                                    class="mb-4">
                                    <img src={logo} alt="" className='w-28' />
                                </div>
                                <h1 className=' text-[#575757]'>
                                    Say goodbye to manual billing and hello to automated invoicing, online shop, inventory management, and expense tracking. </h1>
                                <div className=" mt-5">
                                    <div class="hidden md:block">
                                        <div class=" ">
                                            <div class="">
                                                <div class="md:flex">
                                                    <div class="xl:w-full ">
                                                        <Getlink small={true}
                                                            setError={() => {

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="lg:hidden md:hidden text-center">
                                <div
                                    class="mb-4 flex justify-center">
                                    <img src={logo} alt="" className='w-28' />
                                </div>
                                <h1 className='text-center text-[#575757]'>
                                    Say goodbye to manual billing and hello to automated invoicing, online shop, inventory management, and expense tracking. </h1>

                                <div className=" mt-5">
                                    <div class="hidden md:block">
                                        <div class=" ">
                                            <div class="">
                                                <div class="md:flex">
                                                    <div class="xl:w-full ">
                                                        <Getlink small={true}
                                                            setError={{}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:hidden  md:hidden">
                                    <span className="flex justify-center space-x-4
                                     items-center   pt-4 pb-4">
                                        <NavLink to="https://www.facebook.com/apnabillbook" target="_blank" rel="noreferrer">
                                            <GrFacebookOption className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" /></NavLink>
                                        <NavLink to="https://www.youtube.com/channel/UCQGyKnLpuKuLeeWg5UwTT6A" target="_blank">
                                            <GrYoutube className=" text rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" />
                                        </NavLink>
                                        <NavLink to="https://www.instagram.com/apnabillbook/" target="_blank" rel="noreferrer">
                                            <IoLogoInstagram className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" /></NavLink>
                                        <NavLink to="https://www.linkedin.com/company/apna-softwares/about/" target="_blank">
                                            <SiLinkedin className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" />
                                        </NavLink>
                                    </span>
                                </div>
                            </div>

                            <div class="lg:ml-20 md:block hidden ml-6">

                                <h1 class="mb-4">
                                    <NavLink to="/feature" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Features
                                    </NavLink>
                                </h1>
                                <h1 class="mb-4">
                                    <NavLink to="/online-store" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Online Store</NavLink>
                                </h1>
                                <h1 className='mb-4'>
                                    <NavLink
                                        to="/inventory-managment"
                                        className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                    >
                                        Inventory Management
                                    </NavLink>
                                </h1>
                                <h1 class="mb-4">
                                    <NavLink to="/customer-udhaar" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Customer Udhaar</NavLink>
                                </h1>
                                <h1 class="mb-4">
                                    <NavLink to="/expense-managment" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Expense Management</NavLink>
                                </h1>
                                <h1 class="mb-4">
                                    <NavLink to="/quick-billing" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Quick Billing</NavLink>
                                </h1>
                                <h1 class="mb-4">
                                    <NavLink to="/reports-analytics" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Reports & Analytics</NavLink>
                                </h1>
                            </div>
                            <div class="lg:ml-28  md:block hidden ">

                                <h1 className='mb-4'>
                                    <NavLink to="/privacy-policy" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Privacy Policy</NavLink>
                                </h1>
                                <h1 class="mb-4">
                                    <NavLink to="/terms-conditions" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Terms & Conditions</NavLink>
                                </h1>
                                <h1 class="mb-4">
                                    <NavLink to="/blog" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Blogs</NavLink>
                                </h1>
                                <h1 class="mb-4">
                                    <NavLink to="/faq" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >FAQ's</NavLink>
                                </h1>
                                <h1 class="mb-4">
                                    <NavLink to="/security-notice" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Security Notice</NavLink>
                                </h1>
                                <h1 class="mb-4">
                                    <NavLink to="/refund-policy" className={({ isActive }) =>
                                        isActive
                                            ? "text-[#575757] border-b border-[#0E7CF4]"
                                            : "text-[#575757]"
                                    }
                                    >Refund Policy</NavLink>
                                </h1>

                            </div>
                            {/* responsive */}
                            <div className="lg:hidden md:hidden grid grid-cols-2 gap-2">
                                <div class=" ">

                                    <h1 class="mb-4">
                                        <NavLink to="feature" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Features</NavLink>
                                    </h1>
                                    <h1 class="mb-4">
                                        <NavLink to="/online-store" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Online Store</NavLink>
                                    </h1>
                                    <h1 class="mb-4">
                                        <NavLink to="/inventory-managment" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Inventory Management</NavLink>
                                    </h1>
                                    <h1 class="mb-4">
                                        <NavLink to="/customer-udhaar" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Customer Udhaar</NavLink>
                                    </h1>
                                    <h1 class="mb-4">
                                        <NavLink to="/expense-managment" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Expense Management </NavLink>
                                    </h1>
                                    <h1 class="mb-4">
                                        <NavLink to="/quick-billing" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Quick Billing</NavLink>
                                    </h1>
                                    <h1 class="mb-4">
                                        <NavLink to="/reports-analytics" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Reports & Analytics</NavLink>
                                    </h1>
                                </div>
                                <div class="">
                                    <h1 class="mb-4">
                                        <NavLink to="/blog" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Blogs</NavLink>
                                    </h1>
                                    <h1 class="mb-4">
                                        <NavLink to="/faq" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >FAQ's</NavLink>
                                    </h1>
                                    <h1 class="mb-4">
                                        <NavLink to="/security-notice" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Security Notice</NavLink>
                                    </h1>
                                    <h1 class="mb-4">
                                        <NavLink to="/refund-policy" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Refund Policy</NavLink>
                                    </h1>
                                    <h1 className='mb-4'>
                                        <NavLink to="/privacy-policy" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Privacy Policy</NavLink>
                                    </h1>
                                    <h1 class="mb-4">
                                        <NavLink to="/terms-conditions" className={({ isActive }) =>
                                            isActive
                                                ? "text-[#575757] border-b border-[#0E7CF4]"
                                                : "text-[#575757]"
                                        }
                                        >Terms & Conditions</NavLink>
                                    </h1>
                                </div>
                            </div>
                            <div className="hidden lg:block md:block xl:ml-16 md:ml-0">
                                <span className="flex justify-start  space-x-4
                                     items-center   pt-4 pb-4">
                                    <NavLink to="https://www.facebook.com/apnabillbook" target="_blank" rel="noreferrer">
                                        <GrFacebookOption className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" />
                                    </NavLink>
                                    <NavLink to="https://www.youtube.com/channel/UCQGyKnLpuKuLeeWg5UwTT6A">
                                        <GrYoutube className=" text rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" />
                                    </NavLink>
                                    <NavLink to="https://www.instagram.com/apnabillbook/" target="_blank" rel="noreferrer">
                                        <IoLogoInstagram className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" /></NavLink>
                                    <NavLink to="https://www.linkedin.com/company/apna-softwares/about/">
                                        <SiLinkedin className=" text  rounded-full p-2 bg-[#0e7cf2] text-white h-9 w-9" />
                                    </NavLink>
                                </span>
                            </div>

                        </div>
                    </div>

                </footer>
                <div class="buttonbg p-6 text-center text-[16px] text-white">
                    <span>Copyright &copy; 2024 all rights reserved Apna Softwares</span>

                </div>
            </div>
        </>
    )
}

export default Footer