
import React, { useState, useEffect } from 'react'
import PageTitle from '../Section/PageTitel/Pagetitle';
import { Link } from 'react-router-dom'

const Faq = ({ title, fromHome = false }) => {
  const [openTab, setOpenTab] = useState(-1);

  const handleToggle = (idx) => {
    if (openTab === idx) {
      setOpenTab(-1);
    } else {
      setOpenTab(idx);
    }
  };
  const [fix, setFix] = useState(false);

  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", Navstick);
    return () => {
      window.removeEventListener("scroll", Navstick);
    };
  }, []);

  const faqItems = [
    {
      question: 'What is Apna Billbook?',
      answer:
        'Apna Billbook is a comprehensive business management software that helps you streamline billing, invoicing, inventory management, and more. Its designed to simplify various aspects of running a business.',
    },
    {
      question: 'How can I get started with Apna Billbook?',
      answer:
        'To get started, simply sign up for an account on our website. Once registered, you can access and use Apna Billbook features',
    },
    {
      question: 'What types of businesses can benefit from Apna Billbook?',
      answer:
        'Apna Billbook is suitable for a wide range of businesses, including retail stores, restaurants, online shops, freelancers, and service providers',
    },
    {
      question: 'Is Apna Billbook suitable for small businesses?',
      answer:
        'Yes, Apna Billbook is ideal for small businesses, as it offers a user-friendly interface and cost-effective plans to meet the needs of small enterprises.',
    },
    {
      question: 'Can I create customized bills and invoices with Apna Billbook?',
      answer:
        'Absolutely! Apna Billbook allows you to create professional, branded bills and invoices with your logo and company details.',
    },
    {
      question: 'Does Apna Billbook support GST compliance?',
      answer:
        'Yes, Apna Billbook is GST-compliant and helps you generate GST invoices and reports, making it easier to stay compliant with tax regulations',
    },
    {
      question: 'How can I manage my inventory with Apna Billbook?',
      answer:
        'Apna Billbook provides tools for managing product categories, variants, and stock levels, helping you keep track of your inventory effortlessly.',
    },
    {
      question: ' Can I access my billing history and financial reports with Apna Billbook?',
      answer:
        'Yes, you can export billing history, invoice history, GSTR reports, sales reports, and more to gain valuable insights into your business operations',
    },
    {
      question: 'Is there customer support available if I encounter issues or have questions?',
      answer:
        'Yes, we offer a comprehensive support system, including FAQs, video tutorials, and multiple communication channels like email and phone support.',
    },
    {
      question: 'How can I request a demo or get more information about Apna Billbook?',
      answer:
        'You can request a live demo or contact our support team for more information by visiting our "Contact Us" page on the website.',
    },
    {
      question: 'Is my data secure with Apna Billbook?',
      answer:
        'We prioritize data security and employ measures to protect your information. You can learn more about our security practices on our website.',
    },
    {
      question: 'Can I integrate Apna Billbook with other business tools or software?',
      answer:
        'Apna Billbook offers integration options with select business tools to enhance your workflow. Details can be found on our website.',
    },
    {
      question: 'What are the system requirements for using Apna Billbook?',
      answer:
        'Apna Billbook is a web-based application, and it can be accessed from most modern web browsers on both desktop and mobile devices. Theres no need for additional software installations.',
    }
  ];
  const top5Faq = [
    {
      question: 'What is Apna Billbook?',
      answer:
        'Apna Billbook is a comprehensive business management software that helps you streamline billing, invoicing, inventory management, and more. Its designed to simplify various aspects of running a business.',
    },
    {
      question: 'How can I get started with Apna Billbook?',
      answer:
        'To get started, simply sign up for an account on our website. Once registered, you can access and use Apna Billbook features',
    },
    {
      question: 'What types of businesses can benefit from Apna Billbook?',
      answer:
        'Apna Billbook is suitable for a wide range of businesses, including retail stores, restaurants, online shops, freelancers, and service providers',
    },
    {
      question: 'Is Apna Billbook suitable for small businesses?',
      answer:
        'Yes, Apna Billbook is ideal for small businesses, as it offers a user-friendly interface and cost-effective plans to meet the needs of small enterprises.',
    },
    {
      question: 'Can I create customized bills and invoices with Apna Billbook?',
      answer:
        'Absolutely! Apna Billbook allows you to create professional, branded bills and invoices with your logo and company details.',
    },
  ];

  return (
    <>
      <div>
        <div
          className={fix ? "w-full  bg-white" : " bg-white"}
        >

          <div className={`lg:px-16 border border-1 py-3 ${fix ? "fixed border border-1 bg-white w-full mt-16  z-50 " : " "
            }`}>
            <PageTitle
              activeMenu="FAQs"
            />
          </div>
        </div>
        <div className="p-5 bg-sky-100 ">

          <div className="flex justify-center items-start my-2">
            <div className="w-full sm:w-10/12 md:w-1/2 my-1">
              <h2 className="text-xl font-semibold text-vnet-blue mb-2">{title}</h2>
              <ul className="flex flex-col">
                {
                  fromHome ? top5Faq.map((item, idx) => (
                    <li key={idx} className="bg-white my-2 shadow-lg border rounded-xl">
                      <h2
                        onClick={() => handleToggle(idx)}
                        className="flex flex-row justify-between items-center font-semibold p-3 cursor-pointer "
                      >
                        <span>{item.question}</span>
                        <svg
                          className={`fill-current text-[#0e7cf2] 
                    h-6 w-6 transform transition-transform duration-500 ${openTab === idx ? 'rotate-180' : ''
                            }`}
                          viewBox="0 0 20 20"
                        >
                          <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                        </svg>
                      </h2>

                      <div
                        className="border-l-2 border-[#0e7cf2] overflow-hidden duration-500 transition-all"
                        style={{
                          maxHeight: openTab === idx ? `${item.answer.length * 20}px` : '0',
                        }}
                      >
                        <div dangerouslySetInnerHTML={{ __html: item.answer }}
                          className="p-3 text-gray-900"
                        />
                      </div>
                    </li>
                  )) :
                    faqItems.map((item, idx) => (
                      <li key={idx} className="bg-white my-2 shadow-lg border rounded-xl">
                        <h2
                          onClick={() => handleToggle(idx)}
                          className="flex flex-row justify-between items-center font-semibold p-3 cursor-pointer "
                        >
                          <span>{item.question}</span>
                          <svg
                            className={`fill-current text-[#0e7cf2] 
                    h-6 w-6 transform transition-transform duration-500 ${openTab === idx ? 'rotate-180' : ''
                              }`}
                            viewBox="0 0 20 20"
                          >
                            <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                          </svg>
                        </h2>

                        <div
                          className="border-l-2 border-[#0e7cf2] overflow-hidden duration-500 transition-all"
                          style={{
                            maxHeight: openTab === idx ? `${item.answer.length * 20}px` : '0',
                          }}
                        >
                          <div dangerouslySetInnerHTML={{ __html: item.answer }}
                            className="p-3 text-gray-900"
                          />
                        </div>
                      </li>
                    ))}
              </ul>
              {
                fromHome ? <div className="flex justify-center items-center">
                  <Link to='/faq' >
                    <button
                      className='rounded-lg buttonbg text-lg py-3 mt-5
                     text-white hover:scale-105 px-7 text-center text-[14px]
                    '>
                      View all
                    </button>
                  </Link>
                </div> : null
              }
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Faq;
