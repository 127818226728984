import React, { useEffect, useState } from 'react';
import PageTitle from '../Section/PageTitel/Pagetitle';
import { Helmet } from "react-helmet";
import { BiCalendar } from 'react-icons/bi'
import apiService from '../Baseurl/apiService';
import Facebook from '../../Assest/icons/facebook.png';
import Twitter from '../../Assest/icons/twitter.png';
import Whatsapp from '../../Assest/icons/whatsapp.png';
import LinkedIn from '../../Assest/icons/linkedin.png';
import { Link } from 'react-router-dom';


const BlogContent = () => {
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formattedDate, setFormattedDate] = useState('');
  const [fix, setFix] = useState(false);
  const [pageUrl, setPageUrl] = useState('');

  var facebookShareUrl = `https://www.facebook.com/sharer?u=${encodeURIComponent(pageUrl)}`;
  var twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(pageUrl)}`;
  var linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(pageUrl)}`;
  var whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(pageUrl)}`;
  var instagramShareUrl = `https://www.instagram.com/?url=${encodeURIComponent(pageUrl)}`;
  const Navstick = () => {
    if (window.scrollY >= 30) {
      setFix(true);
    } else {
      setFix(false);
    }
  };

  useEffect(() => {
    fetchData();
    window.addEventListener('scroll', Navstick);
    return () => {
      window.removeEventListener('scroll', Navstick);
    };
  }, []);

  async function fetchData() {
    setLoading(true);
    const currentUrl = window.location.href;
    setPageUrl(currentUrl);

    const pathname = window.location.pathname;
    const id = pathname.split('/').pop();
    const endpoint = `/blogs/getBlogBTitle/${id}`;

    try {
      const response = await apiService.get(endpoint, { headers: { 'Content-Type': 'application/json' } });

      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = response.data;

      if (responseData.data != null) {
        const createdAt = new Date(responseData.data.createdAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        setPageUrl(window.location.href);
        facebookShareUrl = `https://www.facebook.com/sharer?u=${encodeURIComponent(pageUrl)}`;
        twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(pageUrl)}`;
        linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(pageUrl)}`;
        whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(pageUrl)}`;
        instagramShareUrl = `https://www.instagram.com/?url=${encodeURIComponent(pageUrl)}`;
        setFormattedDate(createdAt);
        setBlogPost(responseData.data);
        setLoading(false);
        // update meta data using document

        var title65Char = responseData.data.title;
        document.title = title65Char;
        if (title65Char.length > 65) {
          title65Char = title65Char.substring(0, 65) + '...';
        }
        var description155Char = responseData.data.shortContent;
        if (description155Char.length > 155) {
          description155Char = description155Char.substring(0, 155) + '...';
        }


        document.querySelector('meta[name="title"]')
          .setAttribute("content", title65Char);
        document.querySelector('meta[name="description"]')
          .setAttribute("content", description155Char);
        document.querySelector('meta[name="keywords"]')
          .setAttribute("content", responseData.data.tags);
        document.querySelector('meta[property="og:title"]')
          .setAttribute("content", title65Char);
        document.querySelector('meta[property="og:description"]')
          .setAttribute("content", description155Char);
        document.querySelector('meta[property="og:url"]')
          .setAttribute("content", pageUrl);
        document.querySelector('meta[property="og:image"]')
          .setAttribute("content", responseData.data.mediaLink);
        // document.querySelector('meta[property="og:type"]')
        //   .setAttribute("content", "billing app");

        // twitter meta data
        document.querySelector('meta[name="twitter:title"]')
          .setAttribute("content", title65Char);
        document.querySelector('meta[name="twitter:description"]')
          .setAttribute("content", description155Char);
        document.querySelector('meta[name="twitter:image"]')
          .setAttribute("content", responseData.data.mediaLink);
        // document.querySelector('meta[name="twitter:card"]')
        //   .setAttribute("content", "summary_large_image");
        // document.querySelector('meta[name="twitter:site"]')
        //   .setAttribute("content", "@apnabillbook");
      } else {
        setBlogPost(null);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <>
      {(loading) ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : ((blogPost) ? (
        <>
          <div className={fix ? 'w-full bg-white' : 'bg-white'}>
            <div className={`px-16 border border-1 py-3 ${fix ? 'fixed border border-1 bg-white w-full xl:mt-16 lg:mt-16 mt-16 z-50' : ''}`}>
              <PageTitle activeMenu="Blog" />
            </div>
          </div>
          <div className="mb-14">
            <div className="bg-sky-50 lg:py-20 py-10">
              <h1 className="text-center text-[#313131] lg:text-[50px] text-[25px] font-normal lg:px-40 px-10">
                {blogPost.title}
              </h1>
              <div className='flex justify-center items-center space-x-4 mt-4 '>
                <div className="flex items-center space-x-2">
                  <BiCalendar />
                  <p>{formattedDate}</p>
                </div>
                <p>Team Apna Billbook</p>
              </div>
            </div>
            <div className="mt-10 xl:px-20 lg:px-0 container mx-auto">
              <div className="mb-6">
                <section className="text-gray-700">
                  <div>
                    <div className="lg:flex justify-center">
                      <div className="rounded-lg lg:mb-1 mb-8 ">
                        <img
                          className="rounded-lg h-screen w-full bg-cover object-cover bg-no-repeat hidden md:block lg:block xl:block"
                          src={blogPost.mediaLink}
                          alt={blogPost.title}
                        />
                        <img
                          className="rounded-lg w-full object-cover bg-no-repeat md:hidden lg:hidden xl:hidden h-60 px-5"
                          src={blogPost.mediaLink}
                          alt={blogPost.title}
                        />
                        <div className="p-5 ">
                          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                            {blogPost.title}
                          </h5>
                          <div dangerouslySetInnerHTML={{ __html: blogPost.content }}
                            className='text-gray-700 lg:text-lg font-normal lg:justify-center sm:text-sm md:text-base text-sm px'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Tags */}
                  <div className="flex justify-center px-5">{blogPost.tags}</div>
                  {/* Tags */}
                </section>
              </div>
              <div className="px-5">
                <div className="dashed-line"></div>
                <div className="flex justify-between items-center">
                  <span className='flex items-center space-x-5'>
                    <h1 className='text-xl font-semibold text-[10px]'>Share it on -</h1>
                    <Link to={facebookShareUrl}><img src={Facebook} alt="Facebook Share"
                      width={40}
                      height={40}
                    /></Link>
                    <Link to={linkedinShareUrl}><img src={LinkedIn} alt="LinkedIn Share" width={40}
                      height={40} /></Link>
                    <Link to={twitterShareUrl}><img src={Twitter} alt="Twitter Share" width={40}
                      height={40} /></Link>
                    <Link to={whatsappShareUrl}><img src={Whatsapp} alt="WhatsApp Share" width={38}
                      height={38} /></Link>
                  </span>
                  <div className=""></div>
                </div>
                <div className="dashed-line"></div>
              </div>
            </div>
          </div>

        </>
      ) : (
        // 404 page
        <body>
          <div className="mb-14">
            <div className="bg-sky-50 py-8">
              <h1 className="text-center text-[#313131] lg:text-[50px] text-5xl font-bold">
                404 Page Not Found
              </h1>
              <div className='flex justify-center items-center space-x-4 mt-4 '>
                OOPS! THE PAGE YOU WERE LOOKING FOR, COULD NOT BE FOUND.
              </div>
            </div>
          </div>
        </body>
      ))}
    </>
  );
};

export default BlogContent;
