import React from 'react';
import YouTube from 'react-youtube';
const VideoDialog = ({ isOpen, onClose, videoUrl }) => {
  if (!isOpen) return null;
  const opts = {
    width: '100%',
    breakpoints: {
      400: {
        height: 200,
      },
      1280: {
        height: 640,
      },
    },
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const title = {
    title: 'Apna Billbook'
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center shadow-lg">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative z-10  rounded-lg p-8 lg:w-1/2 ">
        <button
          onClick={onClose}
          className="absolute top-0 lg:right-0 right-7 text-white bg-black p-1 shadow-lg rounded-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 "
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        {/* Replace 'your-video-url.mp4' with the actual video URL */}

        <YouTube
          controls
          videoId={videoUrl}
          title={title}
          opts={opts}
        />

      </div>
    </div>
  );
};

export default VideoDialog;
