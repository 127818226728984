import React, { useState } from 'react'


const Window = () => {

    const handleWindowAppDownload = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/apnabillbook.exe`;
        link.setAttribute('download', 'apnabillbook.exe');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        <div className='md:flex lg:flex justify-center items-center lg:space-x-4 md:space-x-3 cursor-pointer'>

            <div
                onClick={handleWindowAppDownload}
            >
                <div className="flex mt-1 w-40 h-[64px] bg-black text-white rounded-lg items-center justify-center">
                    <div className="lg:mr-4 md:mr-4">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30" height="30">
                            <path fill="#FFF" d="M6 6h17v15H6zm19 0l17-2v17H25zM6 25h17v17H6zm19 0l17 2v15H25z" />
                        </svg>
                    </div>
                    <div>
                        <div className="text-xs">Download for</div>
                        <div className="text-base font-semibold font-sans -mt-1">Windows</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Window