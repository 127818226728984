import React from 'react'
import Slider from 'react-slick';
const Testmonial = () => {
    const sliderSettings = {
        dots: true,
        infinite: true, // Set this to true for an infinite loop
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };


    return (
        <>
            <div class="container mx-auto lg:mt-10 md:px-6 mt-0">
                <section class="mb-10 text-center item-center">
                    <h2 class="lg:mb-20 mb-10  font-bold text-[#313131] lg:text-[50px]  text-2xl">Testimonials</h2>
                    {/* <p className='lg:mb-28 mb-20 text-lg  text-[#575757] '>Wisdom new and valley answer. Contented it so is discourse recommend. Man its upon <br />him call mile. An pasture he himself believe ferrars besides cottage.</p> */}
                    <div class="">
                        <Slider {...sliderSettings} className='mx-20'>
                            <div class="mb-12 md:mb-0">
                                <div class="mb-6 flex justify-center">
                                    <img src="https://app.apnabillbook.com/uploads/1696686783983Screenshot 2023-10-07 at 7.22.50 PM.png"
                                        class="w-[100px] h-[100px] rounded-full shadow-lg dark:shadow-black/20" />
                                </div>
                                <h5 class="mb-2 text-lg font-bold">Om Shanti Hotel</h5>
                                <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                                    Dhabha
                                </h6>
                                <p class="mb-4">
                                    Using Apna Billbook for my dhabha billing and I am very happy with the service. it helps me for collecting online orders and customer khata.
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="inline-block w-6">
                                        <path fill="currentColor"
                                            d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
                                    </svg>
                                </p>
                                <ul class="mb-0 flex justify-center">
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                </ul>
                            </div>
                            <div class="mb-12 md:mb-0">
                                <div class="mb-6 flex justify-center">
                                    <img src="https://app.apnabillbook.com/uploads/1696686662805khanakhaza.png"
                                        class="w-[100px] h-[100px] rounded-full shadow-lg dark:shadow-black/20" />
                                </div>
                                <h5 class="mb-2 text-lg font-bold">Khana Khazana</h5>
                                <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                                    Restaurant
                                </h6>
                                <p class="mb-4">
                                    I am using Apna Billbook for my restaurant billing and I am very happy with the service. It is very easy to use and the support team is very helpful.
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="inline-block w-6">
                                        <path fill="currentColor"
                                            d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
                                    </svg>
                                </p>
                                <ul class="mb-0 flex justify-center">
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m480 757 157 95-42-178 138-120-182-16-71-168v387ZM233 976l65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                </ul>
                            </div>

                            <div class="mb-0">
                                <div class="mb-6 flex justify-center">
                                    <img src="https://app.apnabillbook.com/uploads/1696687118047r04f-Best-Fast-Food-Corner-cake.jpg"
                                        class="w-[100px] h-[100px] rounded-full shadow-lg dark:shadow-black/20" />
                                </div>
                                <h5 class="mb-2 text-lg font-bold">
                                    Bobby Food Corner
                                </h5>
                                <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                                    Street Food Shop
                                </h6>
                                <p class="mb-4">
                                    I am using Apna Billbook for my street food shop billing and I am very happy with the service. It is very easy to use and the support team is very helpful.
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="inline-block w-6">
                                        <path fill="currentColor"
                                            d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
                                    </svg>
                                </p>
                                <ul class="mb-0 flex justify-center">
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
                                        </svg>
                                    </li>
                                </ul>
                            </div>

                            <div class="mb-0">
                                <div class="mb-6 flex justify-center">
                                    <img src="https://ui-avatars.com/api/?name= Paras Enterprises"
                                        class="w-[100px] h-[100px] rounded-full shadow-lg dark:shadow-black/20" />
                                </div>
                                <h5 class="mb-2 text-lg font-bold">
                                    Paras Enterprises
                                </h5>
                                <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                                    Beverage Salesman
                                </h6>
                                <p class="mb-4">
                                    I go daily to around 100 shops for selling beverages products. previously I was using a paper bill book for billing. Now I am using Apna Billbook for billing and I am very happy with the service. It is very easy to use and the support team is very helpful.
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="inline-block w-6">
                                        <path fill="currentColor"
                                            d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
                                    </svg>
                                </p>
                                <ul class="mb-0 flex justify-center">
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                                        </svg>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                                            <path fill="currentColor"
                                                d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
                                        </svg>
                                    </li>
                                </ul>
                            </div>

                        </Slider>
                    </div>

                </section>
            </div>

        </>
    )
}

export default Testmonial