import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { BASE_URL } from '../Baseurl/api';

export default function DynamicTags(props) {
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState([]);
  

  useEffect(() => {
    const apiUrl = `${BASE_URL}/blogs/tags`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to see its structure

        const filteredTags = data.data;
        setTags(filteredTags);
        console.log("=====", filteredTags);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(()=>{
    props.handleTagsChange(value)
  },[value])

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={tags}
      value={value}
     
      onChange={(event, newValue) => {
        setValue(newValue);
        
        console.log("setvakuecd",newValue)
      }}
      renderInput={(params) => (
        <TextField
          
          {...params}
          variant="outlined"
          label="Tags"
          placeholder="Select Tags"
        />
      )}
    />
  );
}
