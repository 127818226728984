import React, { useState, useEffect } from 'react'
import PageTitle from './PageTitel/Pagetitle';
import Getstarted from './Getstarted';

const Trems = () => {
    const [fix, setFix] = useState(false);

    const Navstick = () => {
        if (window.scrollY >= 30) {
            setFix(true);
        } else {
            setFix(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", Navstick);
        return () => {
            window.removeEventListener("scroll", Navstick);
        };
    }, []);

    return (
        <>
            <div
                className={fix ? "w-full  bg-white" : " bg-white"}
            >
                <div className={`lg:px-16 border border-1 py-3 ${fix ? "fixed border border-1 bg-white w-full mt-16 z-50 " : " "
                    }`}>
                    <PageTitle
                        activeMenu="Terms & Conditions"
                    />
                </div>
            </div>
            <div className="mb-14">
                <div className="">
                    <div className="container mx-auto px-20">

                        <h2 className='text-5xl font-semibold pt-12 pb-4 text-[#000F37]'>Terms and Conditions</h2>
                        <p className='text-lg'>Welcome to Apna Billbook! These terms and conditions outline the rules and regulations for the use of Apna Billbook's website and services.
                        </p>
                        <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>1. Acceptance of Terms</h2>
                        <p className='text-lg mt-3'>By accessing this website and using our services, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, you may not use our website or services.</p>
                        <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>2. Use of Services</h2>
                        <p className='text-lg mt-3'>
                            You may use our services only for lawful purposes and in accordance with these terms and conditions. You agree not to use our services:<br />
                            {violationPoints.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}

                        </p>
                        <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>3. User Accounts</h2>
                        <p className='text-lg mt-3'>You may be required to create an account in order to access our services. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
                        <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>4. Intellectual Property</h2>
                        <p className='text-lg mt-3'>All content and materials available on our website and services, including but not limited to text, graphics, logos, button icons, images, audio clips, data compilations and software, are the property of Apna Billbook or its content suppliers and are protected by applicable copyright, trademark and other intellectual property laws.</p>
                        <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>5. Limitation of Liability</h2>
                        <p className='text-lg mt-3'>Apna Billbook shall not be liable for any indirect, incidental, special, consequential or punitive damages arising out of or in connection with your use of our website or services, including but not limited to lost profits, lost data or business interruption.</p>
                        <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>6. Termination</h2>
                        <p className='text-lg mt-3'>We reserve the right to terminate or suspend your access to our website or services at any time, with or without notice, for any reason or no reason at all.</p>
                        <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>7. Governing Law </h2>
                        <p className='text-lg mt-3'>These terms and conditions shall be governed by and construed in accordance with the laws of Uttar Pradesh,India and any disputes relating to these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India.</p>
                        <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>8. Changes to Terms and Conditions</h2>
                        <p className='text-lg mt-3 mb-7'>We reserve the right to modify or amend these terms and conditions at any time, with or without notice. By continuing to use our website or services after such modifications or amendments have been made, you agree to be bound by the revised terms and conditions.</p>
                        <h2 className='text-3xl font-semibold mt-5 text-[#000F37]'>9. Contact Us</h2>
                        <p className='text-lg mt-3 mb-7'>If you have any questions or concerns about these terms and conditions, please contact us at feedback@apnabillbook.com.</p>
                    </div>
                </div>
            </div>
            <Getstarted/>
        </>
    )
}

export default Trems
const violationPoints = [
    "In any way that violates any applicable federal, state, local or international law or regulation.",
    "To impersonate or attempt to impersonate Apna Billbook, a Apna Billbook employee, another user or any other person or entity.",
    "To engage in any activity that interferes with or disrupts our services or servers.",
    "To attempt to access any restricted or protected areas of our website or servers."
];
// and having corporate identity number L45101DL2006PLC148314