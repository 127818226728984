import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../Baseurl/api';

function App() {

  const [tags, setTags] = useState([]);

  function addMetaData(metaKeyword) {
    const keywords = metaKeyword;
    document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
}

  useEffect(() => {

    const apiUrl = `${BASE_URL}/blogs/tags`;


    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {

  
        if (Array.isArray(data.data)) {
          setTags(data.data);
          const keywordsArray = data.data;
          const formattedKeywords = keywordsArray.map(keyword => `"${keyword}"`).join(", ");
          addMetaData(formattedKeywords); // Pass formattedKeywords directly
      }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  return (
    <>
      <h1 className='text-xl font-serif font-semibold lg:text-start text-center text-[#0E7CF4] mb-6'>
        Popular Tags
      </h1>
      <div className="tagcloud tagcloud-container">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="text-black border py-3 px-3 rounded-lg m-3 text-xs font-normal"
          >
            {tag}
          </div>
        ))}
      </div>
    </>
  );
}

export default App;
